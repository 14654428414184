import { Model, model, modelAction, prop } from "mobx-keystone";

export type PatientSexes = "" | "Male" | "Female";

export type PatientHeightUnits = "cm" | "ft | inch";

export type PatientWeightUnits = "lbs" | "kg";

export type PatientBloodTypes =
  | ""
  | "Not sure"
  | "O+"
  | "O-"
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-";

export type PatientEthnicities =
  | ""
  | "I do not wish to answer"
  | "Arab"
  | "Black"
  | "East Asian"
  | "South Asian"
  | "West Asian"
  | "Latin American"
  | "White"
  | "Aboriginal/First Nations"
  | "Other";

export type PatientGenders = "" | "Woman" | "Man" | "Non-binary" | "Prefer not to disclose" | "Prefer to self-describe";

@model("covid/Patient")
export default class Patient extends Model({
  user: prop<identifier>(),
  name: prop(""),
  age: prop<number>(),
  sex: prop<PatientSexes>(""),
  height: prop<number | null>(),
  heightUnits: prop<PatientHeightUnits>(),
  picture: prop<string | null>(),
  weight: prop<number | null>(),
  weightUnits: prop<PatientWeightUnits>(),
  bloodType: prop<PatientBloodTypes>(),
  ethnicity: prop<PatientEthnicities>(),
  ethnicityOther: prop(""),
  country: prop(""),
  zip: prop(""),
  gender: prop<PatientGenders>(""),
  genderOther: prop(""),
}) {
  @modelAction
  setPicture(picture: string | null) {
    this.picture = picture;
  }
}
