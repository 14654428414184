import base, { APIDetail } from "./base";

export interface APIUser {
  id: identifier;
  allowResearchersAccredited: boolean;
  allowResearchersCommercial: boolean;
  allowResearchersFreelance: boolean;
  country: string;
  email: string | null;
  emailUnverified: string | null;
  mobileNumber: string;
}

export interface APIUsersEmailCancel extends APIDetail<APIUser> {}
export const emailCancel = (email: string) =>
  base.post<APIUsersEmailCancel>("users/emails/cancel/", { json: { email } });

export interface APIUsersEmailVerify extends APIDetail<APIUser> {}
export const emailVerify = (verifyToken: string) =>
  base.post<APIUsersEmailVerify>("users/emails/verify/", { json: { verifyToken } });
