import { Layout } from "antd";
import { Loadable } from "components";
import { observer } from "mobx-react-lite";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { hot } from "react-hot-loader";
import { useTranslation } from "react-i18next";
import lazy from "react-lazy-with-preload";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "services";
import { FlashMessageList, FlashProvider } from "services/events";
import { RootStore, StoreProvider, useStore } from "stores";
import styled from "styled-components";
import urls from "urls";
import AuthenticatedRoute from "./AuthenticatedRoute";
import GuestRoute from "./GuestRoute";
import LogoutPage from "./LogoutPage";
import NotFoundPage from "./NotFoundPage"; // No need to lazy import.

const pages = {
  AboutUsPage: lazy(() => import("pages/AboutUsPage")),
  ContactPage: lazy(() => import("pages/ContactPage")),
  HomePage: lazy(() => import("pages/home/HomePage")),
  FollowingHomePage: lazy(() => import("pages/home/FollowingHomePage")),
  FeedbackPage: lazy(() => import("pages/FeedbackPage")),
  LandingPage: lazy(() => import("pages/LandingPage")),
  LoginPage: lazy(() => import("pages/LoginPage")),
  PrivacyPolicyPage: lazy(() => import("pages/PrivacyPolicyPage")),
  RegistrationPage: lazy(() => import("pages/RegistrationPage")),
  SettingsPage: lazy(() => import("pages/settings/SettingsPage")),
  StudiesConsentPage: lazy(() => import("pages/studies/StudiesConsentPage")),
  StudiesDetailPage: lazy(() => import("pages/studies/StudiesDetailPage")),
  StudiesJoinPage: lazy(() => import("pages/studies/StudiesJoinPage")),
  SurveysAnswerPage: lazy(() => import("pages/surveys/SurveysAnswerPage")),
  SurveysHistoryPage: lazy(() => import("pages/surveys/SurveysHistoryPage")),
  UsersEmailVerifyPage: lazy(() => import("pages/users/UsersEmailVerifyPage")),
  UsersProfileCreatePage: lazy(() => import("pages/users/UsersProfileCreatePage")),
  UsersProfileDetailPage: lazy(() => import("pages/users/UsersProfileDetailPage")),
  TermsOfUsePage: lazy(() => import("pages/TermsOfUsePage")),
  FollowingPage: lazy(() => import("pages/FollowingPage")),
  FollowersPage: lazy(() => import("pages/FollowersPage")),
};

Object.entries(pages).forEach(([, page]) => {
  page.preload();
});

const StyledLayout = styled(Layout)`
  height: 100%;
  background: none;

  .ant-layout-content {
    height: 100%;
  }
`;

const StyledLayoutContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
`;

interface Props {
  store: RootStore;
}

const Container: React.FC = observer(() => {
  const [initialized, setInitialized] = useState(false);
  const store = useStore();
  const {
    auth: { isLoggedIn },
    onboarding,
  } = store;

  useEffect(() => {
    (async () => {
      await store.load();
      await store.surveys.checkSurveyStatuses();
      setInitialized(true);
    })();
  }, [store]);

  if (!initialized) return <Loadable topScreen />;

  const isProfileComplete = onboarding.isComplete;

  return (
    <Router history={history}>
      <StyledLayout>
        <StyledLayoutContent>
          <Suspense fallback={<Loadable topScreen />}>
            <Switch>
              <Route exact path="/404">
                <NotFoundPage />
              </Route>

              {!isLoggedIn && (
                <GuestRoute isLoggedIn={false} exact path={urls.rootPath}>
                  <pages.LandingPage />
                </GuestRoute>
              )}

              <Route exact path={urls.privacyPolicyPath}>
                <pages.PrivacyPolicyPage />
              </Route>

              <Route exact path={urls.termsOfUsePath}>
                <pages.TermsOfUsePage />
              </Route>

              <GuestRoute isLoggedIn={isLoggedIn} exact path={urls.loginPath}>
                <pages.LoginPage />
              </GuestRoute>

              <GuestRoute isLoggedIn={isLoggedIn} exact path={urls.registerPath}>
                <pages.RegistrationPage />
              </GuestRoute>

              <Route exact path={urls.logoutPath}>
                <LogoutPage />
              </Route>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.aboutPath}
              >
                <pages.AboutUsPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.feedbackPath}
              >
                <pages.FeedbackPage />
              </AuthenticatedRoute>

              <Route
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.contactPath}
              >
                <pages.ContactPage />
              </Route>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.settingsPath}
              >
                <pages.SettingsPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.settingsShortcutPath}
              >
                <Redirect to={`${urls.settingsPath}?surveyReminders=1`} />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.surveysDailyShortcutPath}
              >
                <Redirect to={urls.rootPath} />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={[
                  urls.surveysAnswerPath(":surveyId"),
                  urls.surveysAnswerResultPath(":surveyId"),
                ]}
              >
                <pages.SurveysAnswerPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.studiesConsentPath(":studyId")}
              >
                <pages.StudiesConsentPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.studiesDetailPath(":studyId")}
              >
                <pages.StudiesDetailPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.studiesJoinPath(":studyId")}
              >
                <pages.StudiesJoinPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={[
                  urls.surveysHistoryPath(":surveyId"),
                  urls.surveysHistoryPath(":surveyId", ":logId"),
                ]}
              >
                <pages.SurveysHistoryPage />
              </AuthenticatedRoute>

              <Route exact path={urls.surveysHistorySharePath(":shareToken")}>
                <pages.SurveysHistoryPage />
              </Route>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.followersPath}
              >
                <pages.FollowersPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.followingPath}
              >
                <pages.FollowingPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.profileEmailVerificationPath()}
              >
                <pages.UsersEmailVerifyPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete
                exact
                path={urls.profileCreatePath}
              >
                <pages.UsersProfileCreatePage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                exact
                path={urls.profileDetailPath(":id")}
              >
                <pages.UsersProfileDetailPage />
              </AuthenticatedRoute>

              <AuthenticatedRoute
                isLoggedIn={isLoggedIn}
                isProfileComplete={isProfileComplete}
                path={urls.followingHomePath(":id")}
              >
                <pages.FollowingHomePage />
              </AuthenticatedRoute>

              {isLoggedIn && (
                <AuthenticatedRoute isLoggedIn isProfileComplete={isProfileComplete} path="">
                  <pages.HomePage />
                </AuthenticatedRoute>
              )}
            </Switch>
          </Suspense>
        </StyledLayoutContent>
      </StyledLayout>
    </Router>
  );
});

const Application: React.FC<Props> = ({ store }) => {
  const { i18n } = useTranslation("translation", { useSuspense: false });
  const [flashMessagesFloating, setFlashMessagesFloating] = useState<FlashMessageList>({});

  return (
    <>
      <Helmet
        defaultTitle="Covid Sentinel"
        htmlAttributes={{ lang: i18n.language }}
        titleTemplate="%s | Covid Sentinel"
      />

      <StoreProvider value={store}>
        <FlashProvider value={[flashMessagesFloating, setFlashMessagesFloating]}>
          <Container />
        </FlashProvider>
      </StoreProvider>
    </>
  );
};

export default hot(module)(Application);
