import type {
  SettingsLanguages,
  SettingsNotificationOptions,
  SettingsTemperatureUnits,
  SettingsWeekStart,
} from "stores/SettingsStore";
import base, { APIDetail } from "./base";

export type ReminderFrequency = "daily" | "weekly" | "monthly";

export interface APISettings {
  surveyRemindersEmail: boolean;
  surveyRemindersEmailFrequency: ReminderFrequency;
  surveyRemindersSms: boolean;
  surveyRemindersSmsFrequency: ReminderFrequency;
  language: SettingsLanguages;
  temperatureUnits: SettingsTemperatureUnits;
  timezone: string;
  watchedNotifications: SettingsNotificationOptions;
  weekStart: SettingsWeekStart;
}

export interface APISettingsInput extends Partial<APISettings> {
  email?: string | null;
  // mobileNumber?: string;
}

export interface APISettingsExtra extends APISettings {
  user: {
    email: string | null;
    emailUnverified: string | null;
    mobileNumber: string;
  };
}

export interface APIDestroy {
  mobileNumber: string;
}

export interface APISettingsDetail extends APIDetail<APISettingsExtra> {}
export const detail = () => base.get<APISettingsDetail>("users/settings/");

export interface APISettingsUpdate extends APIDetail<APISettingsExtra> {}
export const update = (data: APISettingsInput) =>
  base.post<APISettingsUpdate>("users/settings/", { json: data });

export const destroy = (data: APIDestroy) => base.deleteEmpty("users/~destroy/", { json: data });
