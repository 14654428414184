import api from "api";
import type { APISettings, APISettingsInput } from "api/settings";
import { getRoot, Model, model, modelAction, modelFlow, prop, _async, _await } from "mobx-keystone";
import moment from "moment";
import { changeLanguage } from "utils/i18n";
import type RootStore from "./RootStore";

export type SettingsLanguages =
  | "ar"
  | "en"
  | "es"
  | "fr"
  | "tl"
  | "de"
  | "hi"
  | "it"
  | "ms"
  | "pt"
  | "pt-br"
  | "ru"
  | "sw"
  | "zh-hans"
  | "cs"
  | "ja"
  | "ind"
  | "bn"
  | "uk"
  | "ur";

export type SettingsNotificationOptions = "sms" | "email" | "sms/email";

export type SettingsTemperatureUnits = "c" | "f";

export type SettingsWeekStart = "1" | "6" | "7";

export type SettingsReminderFrequency = "daily" | "weekly" | "monthly";

@model("covid/SettingsStore")
export default class SettingsStore extends Model({
  surveyRemindersSms: prop<boolean>(true),
  surveyRemindersSmsFrequency: prop<SettingsReminderFrequency>("daily"),
  surveyRemindersEmail: prop<boolean>(false),
  surveyRemindersEmailFrequency: prop<SettingsReminderFrequency>("daily"),
  language: prop<SettingsLanguages>("en"),
  temperatureUnits: prop<SettingsTemperatureUnits>("c"),
  timezone: prop<string>("UTC", { setterAction: true }),
  watchedNotifications: prop<SettingsNotificationOptions>("sms"),
  weekStart: prop<SettingsWeekStart>("1"),
}) {
  @modelFlow
  fetch = _async(function* (this: SettingsStore) {
    const { data } = yield* _await(api.settings.detail());

    const rootStore = getRoot<RootStore>(this);
    if (rootStore.auth.user) {
      const { email, emailUnverified, mobileNumber } = data.user;

      rootStore.auth.user.email = email;
      rootStore.auth.user.emailUnverified = emailUnverified;
      rootStore.auth.user.mobileNumber = mobileNumber;
    }

    this.set(data);
  });

  @modelFlow
  update = _async(function* (this: SettingsStore, input: APISettingsInput) {
    const { data } = yield* _await(api.settings.update(input));

    const rootStore = getRoot<RootStore>(this);
    if (rootStore.auth.user) {
      const { email, emailUnverified, mobileNumber } = data.user;

      rootStore.auth.user.email = email;
      rootStore.auth.user.emailUnverified = emailUnverified;
      rootStore.auth.user.mobileNumber = mobileNumber;
    }

    this.set(data);
  });

  @modelAction
  set(response: APISettings) {
    const {
      surveyRemindersEmail,
      surveyRemindersEmailFrequency,
      surveyRemindersSms,
      surveyRemindersSmsFrequency,
      language,
      temperatureUnits,
      timezone,
      watchedNotifications,
      weekStart,
    } = response;

    changeLanguage(language);
    moment.tz.setDefault(timezone);

    this.surveyRemindersEmail = surveyRemindersEmail;
    this.surveyRemindersEmailFrequency = surveyRemindersEmailFrequency;
    this.surveyRemindersSms = surveyRemindersSms;
    this.surveyRemindersSmsFrequency = surveyRemindersSmsFrequency;
    this.language = language;
    this.temperatureUnits = temperatureUnits;
    this.timezone = timezone;
    this.watchedNotifications = watchedNotifications;
    this.weekStart = weekStart;
  }

  @modelAction
  setTimezone(timezone: string) {
    this.timezone = timezone;
    moment.tz.setDefault(timezone);
  }
}
