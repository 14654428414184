import config from "config";
import { connectReduxDevTools, onSnapshot, registerRootStore } from "mobx-keystone";
import React from "react";
import { storage, storageKeySnapshot } from "services";
import AuthStore from "./AuthStore";
import DashboardStore from "./DashboardStore";
import EventStore from "./EventStore";
import FollowerStore from "./FollowerStore";
import OnboardingStore from "./OnboardingStore";
import PatientStore from "./PatientStore";
import RootStore from "./RootStore";
import SettingsStore from "./SettingsStore";
import StudyStore from "./StudyStore";
import SurveyStore from "./SurveyStore";
import UserStore from "./UserStore";

const StoreContext = React.createContext<RootStore>({} as RootStore);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

function createRootStore() {
  const auth = new AuthStore({});
  const dashboard = new DashboardStore({});
  const events = new EventStore({});
  const followers = new FollowerStore({
    followersTotal: 0,
    followingTotal: 0,
    maxFollowers: 10,
  });
  const onboarding = new OnboardingStore({});
  const patients = new PatientStore({});
  const settings = new SettingsStore({});
  const studies = new StudyStore({});
  const surveys = new SurveyStore({});
  const users = new UserStore({});
  const rootStore = new RootStore({
    auth,
    dashboard,
    events,
    followers,
    onboarding,
    patients,
    settings,
    studies,
    surveys,
    users,
  });

  registerRootStore(rootStore);

  onSnapshot(rootStore, (newSnapshot) => {
    storage.setItem(storageKeySnapshot, newSnapshot);
  });

  if (config.isLocal) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      const remoteDev = require("remotedev");
      const connection = remoteDev.connectViaExtension({
        name: "COVID Sentinel",
      });

      connectReduxDevTools(remoteDev, connection, rootStore);
    } catch (e) {
      // no-op
    }
  }

  return rootStore;
}

export { RootStore, StoreProvider, createRootStore, useStore };
