import base, { APIDetail, APIList } from "./base";
import { APIDashboardItem } from "./dashboard";

export type ShareType = "all" | "survey";
export type Status = 0 | 1;

export interface APIFollower {
  id: identifier;
  avatar: string;
  name: string;
  shareType: ShareType;
  status: Status;
  toUser: identifier;
}
export interface APIFollowersList extends APIList<APIFollower> {
  maxFollowers: number;
}

export const getFollowers = (page = 1, searchParams = {}) =>
  base.list<APIFollowersList>("followers/", page, 0, { searchParams });

export interface APIFollowerDetail extends APIDetail<APIFollower> {}

export const invite = (data = {}) =>
  base.post<APIFollowerDetail>("followers/invite/", { json: data });

export const updateFollower = (id: identifier | string, data = {}) =>
  base.patch<APIFollowerDetail>(`followers/${id}/`, { json: data });

export interface APIFollowing {
  id: number;
  avatar: string;
  name: string;
  fromUser: number;
  shareType: ShareType;
  summary?: APIDashboardItem[];

  status: Status;
  getEmailNotif: boolean;
  getSmsNotif: boolean;
}

export interface APIFollowingList extends APIList<APIFollowing> {}

export const getFollowing = (page = 1, searchParams = {}) =>
  base.list<APIFollowingList>("followers/following/", page, 0, { searchParams });

export interface APIFollowingDetail extends APIDetail<APIFollowing> {}

export const updateFollowing = (id: identifier | string, data = {}) =>
  base.patch<APIFollowingDetail>(`followers/${id}/following_update/`, { json: data });

export const deleteFollowRequest = (id: identifier | string) =>
  base.deleteEmpty(`followers/${id}/`);

export const resendSMSInvite = (id: identifier | string) =>
  base.postEmpty(`followers/${id}/resend_invite/`);
