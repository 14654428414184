import { Button } from "antd";
import { ReactComponent as HamburgerIcon } from "assets/images/hamburger.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import AppDrawer from "components/AppDrawer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import urls from "urls";
import { ReactComponent as BackIcon } from "./iconBack.svg";

const appHeaderNoChildrenHeight = "50px";
const appHeaderWithChildrenHeight = "36px";

interface ContainerProps {
  hasChildren?: boolean;
}

interface MenuButtonProps {
  hasLeftMenu?: boolean;
  showBackButton?: boolean;
}

const AppHeader = styled.div<MenuButtonProps>`
  line-height: ${appHeaderNoChildrenHeight};
  height: ${appHeaderNoChildrenHeight};
  margin: 0 14px;
  position: relative;
`;

const ChildHeader = styled.div<MenuButtonProps>`
  ${({ hasLeftMenu, showBackButton }) =>
    !hasLeftMenu &&
    !showBackButton &&
    css`
      margin-top: -26px;
    `}
`;

const MenuButton = styled.button<MenuButtonProps>`
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  line-height: ${appHeaderNoChildrenHeight};
  margin-left: -14px;
  padding: 0 21px;
  vertical-align: middle;
`;

const StyledLogo = styled(Logo)`
  height: 40px;
  width: auto;
  vertical-align: middle;
`;

interface StyledLinkProps {
  hasRightView: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  flex: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);

  ${({ hasRightView }) =>
    !hasRightView &&
    css`
      @media (max-width: 480px) {
        left: inherit;
        right: 14px;
        transform: translateY(-50%) translateX(0%);
      }
    `}
`;

const StyledButton = styled(Button)`
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 0;
  vertical-align: middle;

  span {
    color: #333344;
    padding-left: 5px;
  }

  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

const Container = styled.div<ContainerProps>`
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 1px #ddd;
  width: 100%;

  a {
    line-height: 1;
  }

  ${({ hasChildren }) =>
    hasChildren &&
    css`
      @media (max-width: 799px) {
        ${AppHeader} {
          height: ${appHeaderWithChildrenHeight};
          line-height: ${appHeaderWithChildrenHeight};
        }

        ${StyledLogo} {
          height: 32px;
        }

        ${MenuButton} {
          height: ${appHeaderWithChildrenHeight};
          line-height: ${appHeaderWithChildrenHeight};
          margin: 0 -16px;
          padding: 0 14px;
        }
      }
    `}
`;

type Props = React.PropsWithChildren<{
  activeMenuKey?: string;
  backUrl?: string;
  hasLeftMenu?: boolean;
  rightView?: React.ReactNode;
  showBackButton?: boolean;
}>;

const Header: React.FC<Props> = ({
  activeMenuKey,
  backUrl,
  children,
  hasLeftMenu,
  rightView,
  showBackButton,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const handleLeftMenuClick = () => {
    setIsDrawerVisible((prev) => !prev);
  };

  const handleBackButtonClick = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  const handleMenuClose = () => {
    setIsDrawerVisible(false);
  };

  const reallyShowBackButton = showBackButton && (history.length > 1 || !!backUrl);

  return (
    <Container hasChildren={!!children}>
      <AppHeader hasLeftMenu={hasLeftMenu}>
        {hasLeftMenu && (
          <AppDrawer
            activeKey={activeMenuKey || history.location.pathname}
            onClose={handleMenuClose}
            visible={isDrawerVisible}
          />
        )}

        {hasLeftMenu && (
          <MenuButton hasLeftMenu={hasLeftMenu} tabIndex={0} onClick={handleLeftMenuClick}>
            <HamburgerIcon />
          </MenuButton>
        )}

        {!hasLeftMenu && reallyShowBackButton && (
          <StyledButton
            icon={<BackIcon />}
            onClick={handleBackButtonClick}
            size="middle"
            type="link"
          >
            {t("app.back")}
          </StyledButton>
        )}

        <StyledLink hasRightView={!!rightView} to={urls.rootPath}>
          <StyledLogo />
        </StyledLink>

        {rightView}
      </AppHeader>
      {children && (
        <ChildHeader hasLeftMenu={hasLeftMenu} showBackButton={reallyShowBackButton}>
          {children}
        </ChildHeader>
      )}
    </Container>
  );
};

export default Header;
