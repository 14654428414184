import base from "./base";
import type { APIPatientOut } from "./patients";
import type { APISettings } from "./settings";
import type { APIUser } from "./users";

export type APIAuthLogin = null;
export type APIAuthLogout = null;
export type APIAuthLogoutAll = null;
export type APIAuthRegister = null;

export interface APIOnboarding {
  patientProfile: boolean;
  baselineSurvey: boolean;
  mentalHealthSurvey: boolean;
  dailySurvey: boolean;
}

export interface APIAuthReauth {
  data: {
    expiry: string | null;
    settings: APISettings;
    token: string;
    patient: APIPatientOut | null;
    user: APIUser;
    onboarding: APIOnboarding;
  };
}

export type APIAuthVerify = APIAuthReauth;

export const login = (mobileNumber: string) =>
  base.postEmpty("auth/login/", { json: { mobileNumber }, public: true });

export const logout = () => base.postEmpty("auth/logout/");

export const logoutAll = () => base.postEmpty("auth/logoutall/");

export const reauth = () => base.post<APIAuthReauth>("auth/reauth/");

export const register = (data: {
  allowResearchersAccredited: boolean;
  allowResearchersCommercial: boolean;
  allowResearchersFreelance: boolean;
  country: string;
  mobileNumber: string;
  language: string;
}) => base.postEmpty("auth/register/", { json: { ...data }, public: true });

export const verify = (mobileNumber: string, token: string) =>
  base.post<APIAuthVerify>("auth/verify/", { json: { mobileNumber, token }, public: true });

export interface APIGeo {
  data: {
    countryCode: string;
  };
}

export const geo = () => base.get<APIGeo>("auth/geo/");
