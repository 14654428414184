import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

type RadioType = "radio" | "check";

interface ContainerProps {
  bordered?: boolean;
  disabled?: boolean;
  flipped?: boolean;
  isChecked: boolean;
}

const Container = styled.button<ContainerProps>`
  align-items: center;
  background: white;
  border: ${({ bordered }) => (bordered ? "1px solid #bdbdbd" : "none")};
  border-color: ${({ isChecked }) => (isChecked ? "#205a9d" : "#bdbdbd")};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  flex-direction: ${({ flipped }) => (flipped ? "row-reverse" : "row")};
  padding: ${({ bordered }) => (bordered ? "5px 0 5px 7.5px" : "0")};
  width: 100%;
`;

interface RadioButtonProps {
  isChecked: boolean;
  type?: RadioType;
}

const RadioButton = styled.div<RadioButtonProps>`
  align-items: center;
  background-color: ${({ isChecked, type }) =>
    isChecked && type === "check" ? "#205a9d" : "transparent"};
  border-color: ${({ isChecked }) => (isChecked ? "#205a9d" : "#666974")};
  border-radius: ${({ type }) => (type === "radio" ? "10px" : "0")};
  border-style: solid;
  border-width: 2px;
  color: #ffffff;
  display: flex;
  font-size: 10px;
  height: ${({ type }) => (type === "radio" ? "20px" : "16px")};
  justify-content: center;
  margin-right: 8px;
  position: relative;
  transition: all 200ms ${({ isChecked }) => (isChecked ? "ease-in" : "ease-out")};
  width: ${({ type }) => (type === "radio" ? "20px" : "16px")};
`;

interface RadioButtonFillProps {
  isChecked: boolean;
}

const RadioButtonFill = styled.div<RadioButtonFillProps>`
  background-color: ${({ isChecked }) => (isChecked ? "#205a9d" : "#666974")};
  border-radius: 5px;
  height: 10px;
  transform: ${({ isChecked }) => (isChecked ? "none" : "scale(0, 0)")};
  transition: all 200ms ${({ isChecked }) => (isChecked ? "ease-in" : "ease-out")};
  visibility: ${({ isChecked }) => (isChecked ? "visible" : "hidden")};
  width: 10px;
`;

const RadioText = styled.span`
  flex: 1;
  text-align: left;
  padding-right: 8px;
`;

interface Props<T> {
  bordered?: boolean;
  defaultChecked?: boolean;
  isChecked?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  flipped?: boolean;
  name?: string;
  onChange?: (value: T | undefined, name?: string) => void;
  type?: RadioType;
  value?: T;
}

function Radio<T>({
  bordered,
  children,
  defaultChecked = false,
  disabled,
  flipped,
  isChecked,
  name,
  onChange = () => {},
  type = "radio",
  value,
}: Props<T>) {
  const [isCheckedInternal, setIsCheckedInternal] = useState(defaultChecked);
  const isCheckedActual = isChecked === undefined ? isCheckedInternal : isChecked;

  const handleClick = () => {
    if (disabled) return;

    if (isChecked === undefined) {
      onChange(!isCheckedInternal ? value : undefined, name);
      setIsCheckedInternal((prevIsCheckedInternal) => !prevIsCheckedInternal);
    } else {
      onChange(!isChecked ? value : undefined, name);
    }
  };

  return (
    <Container
      role="radio"
      aria-checked={isCheckedActual}
      bordered={bordered}
      disabled={disabled}
      flipped={flipped}
      isChecked={isCheckedActual}
      onClick={handleClick}
      tabIndex={0}
    >
      <RadioButton isChecked={isCheckedActual} type={type}>
        {type === "radio" && <RadioButtonFill isChecked={isCheckedActual} />}
        {type === "check" && isCheckedActual && <FontAwesomeIcon icon="check" />}
      </RadioButton>

      <RadioText>{children}</RadioText>
    </Container>
  );
}

export default Radio;
