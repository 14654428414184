import api from "api";
import { APIStudy } from "api/studies";
import {
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { Study } from "./models";


@model("covid/StudyStore")
export default class StudyStore extends Model({
  studies: prop_mapObject(() => new Map<number, Study>()),
  isLoading: prop(true),
}) {
  private page = 1;

  hasNext = true;

  get studiesList() {
    return Array.from(this.studies, ([, v]) => v);
  }

  @modelAction
  saveStudy(input: APIStudy) {
    const study = this.studies.set(input.id, new Study(input));
    return study.get(input.id);
  }

  @modelFlow
  loadStudies = _async(function* (this: StudyStore, restart = false) {
    if (restart) {
      this.page = 1;
      this.studies.clear();
      this.hasNext = true;
    }

    if (!this.hasNext) {
      return;
    }

    this.isLoading = true;

    const { data, next } = yield* _await(api.studies.list(this.page));

    if (next) {
      this.page += 1;
    } else {
      this.hasNext = false;
    }

    data.forEach(study => this.saveStudy(study));

    this.isLoading = false;
  });

  @modelFlow
  detail = _async(function* (this: StudyStore, id: identifier | string) {
    const { data } = yield* _await(api.studies.detail(id));

    return this.saveStudy(data);
  });

  @modelFlow
  inAppConsent = _async(function* (this: StudyStore, id: identifier | number) {
    const { data } = yield* _await(api.studies.inAppConsent(id));
    return this.saveStudy(data);
  });

  @modelFlow
  resendEmailConsent = _async(function* (this: StudyStore, id: identifier | number) {
    yield* _await(api.studies.resendEmailConsent(id));
  });

  @modelFlow
  unconsent = _async(function* (this: StudyStore, id: identifier | number) {
    const { data } = yield* _await(api.studies.unconsent(id));
    return this.saveStudy(data);
  });
}
