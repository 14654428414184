import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

const TextInput = styled(Input)<InputProps>`
  background: #ffffff;
  border: 1px solid #205a9d;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  padding: 8px 10px 7px 8px;

  &[readonly] {
    background-color: #f5f5f5;
  }

  &[value=""],
  &[value=""][disabled]:hover {
    border: 1px solid #bdbdbd;
  }

  &:focus,
  &:hover,
  &[disabled]:hover {
    border: 1px solid #205a9d;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(32, 89, 173, 0.2);
  }

  &::placeholder {
    color: #bdbdbd;
    letter-spacing: 0.1px;
  }
`;

export const NativeTextInput = styled.input`
  background: #ffffff;
  border: 1px solid #205a9d;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  padding: 8px 10px 7px 8px;

  &[value=""] {
    border: 1px solid #bdbdbd;
  }

  &:focus {
    border-color: #205a9d;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &::placeholder {
    color: #bdbdbd;
    letter-spacing: 0.1px;
  }
`;

const TextAreaInput = styled(Input.TextArea)`
  background: #ffffff;
  border: 1px solid #205a9d;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  padding: 8px 10px 7px 8px;

  &:placeholder-shown {
    border: 1px solid #bdbdbd;
  }

  &:focus {
    border-color: #205a9d;
    box-shadow: none;
  }

  &::placeholder {
    color: #bdbdbd;
    letter-spacing: 0.1px;
  }
`;

// @ts-ignore
TextInput.TextArea = TextAreaInput;

export default TextInput;
