/* eslint-disable react/no-array-index-key */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Slider, Upload } from "antd";
import {
  HttpRequestHeader,
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import api from "api";
import { apiConfig } from "api/base";
import { APIPatientsPictureUpload } from "api/patients";
import Loadable from "components/Loadable";
import Modal from "components/Modal";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { flash } from "services";
import styled, { css } from "styled-components";
import urls from "urls";
import { ReactComponent as PlusIcon } from "./iconPlus.svg";
import { ReactComponent as MinusIcon } from "./iconMinus.svg";

const cropSize = { width: 104, height: 104 };
const defaultMaxZoom = 4;
const defaultMinZoom = 1;
const maxFileSize = 1;
const stepZoom = 0.1;
const uploadAccept = ".bmp,.gif,.jpg,.jpeg,.png";

interface ContainerProps {
  isLoading: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 40px 0;

  .ant-upload.ant-upload {
    padding: 0;
  }

  .ant-upload-drag {
    background-color: #f4f5f4;
    border-radius: 52px;
    height: 104px;
    margin: 0 auto;
    width: 104px;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .ant-upload-drag {
        border: none;
      }
    `}
`;

const ButtonStyled = styled(Button)`
  display: block;
  margin: 20px auto 0;
`;

const CropperContainer = styled.div`
  background-color: #f4f5f4;
  border-radius: 52px;
  height: 104px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 104px;

  // HACK: method to make "overflow: hidden" work in Safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px auto 0;
  max-width: 240px;
  width: 100%;

  .ant-slider {
    flex: 1;
    margin: 0 12px;
  }

  .ant-slider-handle {
    border-width: 4px;
    height: 18px;
    margin-top: -7px;
    width: 18px;
  }
`;

const UploadIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
  opacity: 0.5;
`;

const UploadHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 15px;
  text-align: center;
`;

const UploadText = styled.p`
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 15px;
  margin-bottom: 20px;
  text-align: center;
  white-space: pre-wrap;
`;

const ZoomButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  line-height: 1;
  padding: 0;
`;

interface Area {
  height: number;
  width: number;
  x: number;
  y: number;
}

interface MediaSize {
  width: number;
  height: number;
  naturalWidth: number;
  naturalHeight: number;
}

interface Point {
  x: number;
  y: number;
}

enum Step {
  New,
  UploadingNew,
  Edit,
  UploadingEdit,
}

interface Props {
  onCancel: () => void;
  onSave: (url: string | null) => void;
  visible: boolean;
}

const AvatarEditModal: React.FC<Props> = ({ onCancel, onSave, visible }) => {
  const [coordinates, setCoordinates] = useState<Point>({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState<Area>();
  const [filename, setFilename] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [key, setKey] = useState(0);
  const [minZoom, setMinZoom] = useState(defaultMinZoom);
  const [maxZoom, setMaxZoom] = useState(defaultMaxZoom);
  const [step, setStep] = useState(Step.New);
  const [zoom, setZoom] = useState(1);
  const { t } = useTranslation();

  const isLoading = [Step.UploadingNew, Step.UploadingEdit].includes(step);
  const uploadHeaders: HttpRequestHeader = {
    Authorization: `Token ${apiConfig.token}`,
  };

  useEffect(() => {
    if (!cropArea || !imageUrl || !filename || step !== Step.UploadingEdit) return;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    const image = new Image();
    image.crossOrigin = "";
    image.src = imageUrl;
    image.onload = async () => {
      const width = Math.min(200, image.height, image.width);
      const height = Math.min(200, image.height, image.width);
      canvas.width = width;
      canvas.height = height;
      context.drawImage(
        image,
        cropArea.x,
        cropArea.y,
        cropArea.width,
        cropArea.height,
        0,
        0,
        width,
        height
      );

      canvas.toBlob(async (blob) => {
        if (!blob) {
          flash({
            id: "users/image_generic",
            type: "error",
            message: t("errors.users/image_generic"),
          });
          onSave(null);
          return;
        }

        try {
          const { data } = await api.patients.pictureUpload(blob, filename);

          onSave(data.picture);
        } catch (e) {
          await api.handleError(e, { all: false });

          flash({ id: "generic/image", type: "error", message: t("errors.generic/image") });
          onSave(null);
        }
      });
    };
  }, [cropArea, imageUrl, filename, onSave, step, t]);

  const handleCancel = () => {
    onSave(null);
  };

  const handleSave = () => {
    setStep(Step.UploadingEdit);
  };

  const handleCropperChange = (coordinatesNew: Point) => {
    setCoordinates(coordinatesNew);
  };

  const handleCropperComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  };

  const handleCropperMediaLoad = (mediaSize: MediaSize) => {
    const calculatedMinZoom = Math.max(
      minZoom,
      cropSize.height / mediaSize.height,
      cropSize.width / mediaSize.width
    );

    if (calculatedMinZoom > maxZoom) {
      setMaxZoom(calculatedMinZoom);
    }

    setMinZoom(calculatedMinZoom);
    setZoom(calculatedMinZoom);
  };

  const handleZoomChange = (zoomNew: number) => {
    setZoom(zoomNew);
  };

  const handleZoomMinus = () => {
    setZoom((prevZoom) => Math.max(prevZoom - stepZoom, minZoom));
  };

  const handleZoomPlus = () => {
    setZoom((prevZoom) => Math.min(prevZoom + stepZoom, maxZoom));
  };

  const handleUploadCheck = (file: RcFile) => {
    if (file.size > maxFileSize * 1024 * 1024) {
      flash({
        id: "users/image_size",
        type: "error",
        message: t("errors.users/image_size", { size: maxFileSize }),
      });
      setKey((prevKey) => prevKey + 1);
      setStep(Step.New);
      return false;
    }

    return true;
  };

  const handleUploadChange = (info: UploadChangeParam<UploadFile<APIPatientsPictureUpload>>) => {
    const { file } = info;

    if (file.status === "uploading") {
      setStep(Step.UploadingNew);
    } else if (file.status === "done") {
      if (file.response && file.response.data.pictureTemp) {
        setFilename(file.name);
        setImageUrl(file.response.data.pictureTemp);
        setStep(Step.Edit);
      } else {
        flash({ id: "generic/image", type: "error", message: t("errors.generic/image") });
        onSave(null);
      }
    }
  };

  let button;
  let headerText;
  let uploadText;

  switch (step) {
    case Step.New:
      headerText = t("usersProfilePage.avatarEditModal.headerNew");
      uploadText = t("usersProfilePage.avatarEditModal.textNewInstructions", { size: maxFileSize });
      break;

    case Step.UploadingNew:
      button = (
        <ButtonStyled onClick={handleCancel} type="primary">
          {t("usersProfilePage.avatarEditModal.buttonCancel")}
        </ButtonStyled>
      );
      headerText = t("usersProfilePage.avatarEditModal.headerUploading");
      break;

    case Step.Edit:
      button = (
        <ButtonStyled onClick={handleSave} type="primary">
          {t("usersProfilePage.avatarEditModal.buttonSave")}
        </ButtonStyled>
      );
      headerText = t("usersProfilePage.avatarEditModal.headerEdit");
      uploadText = t("usersProfilePage.avatarEditModal.textEditInstructions");
      break;

    case Step.UploadingEdit:
      headerText = t("usersProfilePage.avatarEditModal.headerUploading");
      break;

    default:
      break;
  }

  let picture;
  if ([Step.New, Step.UploadingNew].includes(step)) {
    picture = (
      <Upload.Dragger
        accept={uploadAccept}
        action={urls.api.uploadAvatarPath}
        beforeUpload={handleUploadCheck}
        disabled={isLoading}
        headers={uploadHeaders}
        key={key}
        name="picture_temp"
        onChange={handleUploadChange}
        showUploadList={false}
      >
        {!isLoading && <UploadIcon icon={["fal", "plus"]} />}
        {isLoading && <Loadable />}
      </Upload.Dragger>
    );
  } else {
    picture = (
      <CropperContainer>
        <Cropper
          aspect={1}
          crop={coordinates}
          cropSize={cropSize}
          cropShape="rect"
          image={imageUrl}
          key={key}
          maxZoom={maxZoom}
          minZoom={minZoom}
          onCropChange={handleCropperChange}
          onCropComplete={handleCropperComplete}
          onMediaLoaded={handleCropperMediaLoad}
          showGrid={false}
          zoom={zoom}
        />
      </CropperContainer>
    );
  }

  return (
    <Modal centered footer={null} onCancel={onCancel} visible={visible}>
      <Container isLoading={isLoading}>
        {headerText && <UploadHeader>{headerText}</UploadHeader>}
        {uploadText && <UploadText>{uploadText}</UploadText>}
        {picture}
        {step === Step.Edit && (
          <SliderContainer>
            <ZoomButton onClick={handleZoomMinus}>
              <MinusIcon />
            </ZoomButton>

            <Slider
              max={maxZoom}
              min={minZoom}
              onChange={handleZoomChange}
              step={stepZoom}
              value={zoom}
            />

            <ZoomButton onClick={handleZoomPlus}>
              <PlusIcon />
            </ZoomButton>
          </SliderContainer>
        )}
        {button}
      </Container>
    </Modal>
  );
};

export default AvatarEditModal;
