/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */

import {
    Model,
    model,
    prop,
} from "mobx-keystone";


@model("covid/Study")
class Study extends Model({
    id: prop<identifier>(),
    title: prop<string>(),
    description: prop<string>(),
    startDate: prop<Date>(),
    endDate: prop<Date>(),
    logo: prop<string>(),
    survey: prop<identifier>(),
    consentForm: prop<identifier>(),
    isEligible: prop<boolean>(),
    isOpen: prop<boolean>(),
    hasConsentedInApp: prop<boolean>(),
    hasConsented: prop<boolean>(),
}) {}

export { Study };
