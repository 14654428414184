import ky, { Options as KyOptions } from "ky";
import { omit } from "lodash";

import config from "config";

export const apiConfig = {
  defaultLimit: 25,
  token: "",
};

const timeout = 60000;

interface Options extends KyOptions {
  public?: boolean;
}

function getActualOptions(options: Options = {}) {
  const actualOptions: KyOptions = omit(options, ["public"]);

  if (!options.public) {
    actualOptions.headers = {
      Authorization: `Token ${apiConfig.token}`,
      ...actualOptions.headers,
    };
  }

  return actualOptions;
}

// Base methods

async function destroyEmpty(url: string, options?: Options) {
  return ky.delete(url, {
    prefixUrl: config.apiBaseUrl,
    timeout,
    ...getActualOptions(options),
  });
}

async function getEmpty(url: string, options?: Options) {
  return ky.get(url, {
    prefixUrl: config.apiBaseUrl,
    timeout,
    ...getActualOptions(options),
  });
}

async function head(url: string, options?: Options) {
  return ky.head(url, {
    prefixUrl: config.apiBaseUrl,
    timeout,
    ...getActualOptions(options),
  });
}

async function patchEmpty(url: string, options?: Options) {
  return ky.patch(url, {
    prefixUrl: config.apiBaseUrl,
    timeout,
    ...getActualOptions(options),
  });
}

async function postEmpty(url: string, options?: Options) {
  return ky.post(url, {
    prefixUrl: config.apiBaseUrl,
    timeout,
    ...getActualOptions(options),
  });
}

async function destroy<T>(url: string, options?: Options): Promise<T> {
  const response = await destroyEmpty(url, options);

  return response.json();
}

async function get<T>(url: string, options?: Options): Promise<T> {
  const response = await getEmpty(url, options);

  return response.json();
}

async function patch<T>(url: string, options?: Options): Promise<T> {
  const response = await patchEmpty(url, options);

  return response.json();
}

async function post<T>(url: string, options?: Options): Promise<T> {
  const response = await postEmpty(url, options);

  return response.json();
}

// Convenience methods

export interface APIDetail<T> {
  data: T;
}

export interface APIList<T> {
  count: number;
  next: string | null;
  previous: string | null;
  data: T[];
}

async function list<T>(url: string, page = 1, limit = 0, options?: Options): Promise<T> {
  const actualLimit = limit === -1 ? 9999 : limit || apiConfig.defaultLimit;
  const offset = (page - 1) * actualLimit;

  let searchParams = { offset, limit: actualLimit };
  if (options) {
    searchParams = { ...searchParams, ...(options.searchParams as any) };
  }

  return get<T>(url, { ...options, searchParams });
}

const exp = {
  delete: destroy,
  deleteEmpty: destroyEmpty,
  get,
  getEmpty,
  head,
  list,
  patch,
  patchEmpty,
  post,
  postEmpty,
};

export default exp;
