import { Result } from "antd";
import { BaseContainer, Button } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import urls from "urls";

type Props = {};

const NotFoundPage: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <BaseContainer hasLeftMenu title={t("notFoundPage.title")}>
      <Result
        extra={
          <Button href={urls.rootPath} type="primary">
            {t("notFoundPage.backHome")}
          </Button>
        }
        status={404}
        subTitle={t("notFoundPage.content")}
        title="404"
      />
    </BaseContainer>
  );
};

export default NotFoundPage;
