// Import before React
// Others
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin as fabLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faPlus as falPlus,
} from "@fortawesome/pro-light-svg-icons";
import {
  faEnvelope as farEnvelope,
  faGlobe as farGlobe,
  faSquareFull as farCheckSquare,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBalanceScale as fasBalanceScale,
  faBan as fasBan,
  faCheck as farCheck,
  faChevronDoubleLeft as fasChevronDoubleLeft,
  faChevronDoubleRight as fasChevronDoubleRight,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faFlask as fasFlask,
  faHandHoldingHeart as fasHandHoldingHeart,
  faLock as fasLock,
  faNotesMedical as fasNotesMedical,
  faSquareFull as fasCheckSquare,
  faStethoscope as fasStethoscope,
  faTimes as fasTimes,
} from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/browser";
import config from "config";
// Polyfills
import "core-js/es/object/from-entries";
import "moment-timezone";
import "moment/locale/ar";
import "moment/locale/bn";
import "moment/locale/cs";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/hi";
import "moment/locale/id";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ms";
import "moment/locale/pt";
import "moment/locale/pt-br";
import "moment/locale/ru";
import "moment/locale/sw";
import "moment/locale/tl-ph";
import "moment/locale/uk";
import "moment/locale/ur";
import "moment/locale/zh-cn";
import React from "react";
// Import react-calendar default stylesheet
import "react-calendar/dist/Calendar.css";
import ReactDOM from "react-dom";
import "react-hot-loader";
import "react-phone-number-input/style.css";
import { createRootStore } from "stores";
import { initializeGA, initializeGTM } from "utils/ga";
import { version } from "../package.json";
import "./i18n";
// Import global stylesheet
import "./index.scss";
import Application from "./pages";
import * as serviceWorker from "./serviceWorker";

const release = `covid-web@${version}`;
const { gaTrackingCode, gtmCode, sentryDsn } = config;

// Initialize Sentry
if (sentryDsn) {
  console.log("Sentry is enabled");
  Sentry.init({
    dsn: sentryDsn,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release,
  });
} else {
  console.log("Sentry is disabled");
}

// Initialize Google Analytics and Google Tag Manager
if (gaTrackingCode) {
  initializeGA(gaTrackingCode, config.debugGoogleAnalytics);
}

if (gtmCode) {
  initializeGTM(gtmCode, config.debugGoogleTagManager);
}

// Font-awesome configuration
library.add(
  fabLinkedin,
  falChevronLeft,
  falChevronRight,
  falPlus,
  farCheck,
  farCheckSquare,
  farEnvelope,
  farGlobe,
  fasBalanceScale,
  fasBan,
  fasCheckSquare,
  fasChevronDoubleLeft,
  fasChevronDoubleRight,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasFlask,
  fasHandHoldingHeart,
  fasLock,
  fasNotesMedical,
  fasStethoscope,
  fasTimes
);

// Store setup
const store = createRootStore();

// Add <React.StrictMode></<React.StrictMode> after this issue is resolved https://github.com/ant-design/ant-design/issues/22493
ReactDOM.render(<Application store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
