import { Avatar as AntdAvatar } from "antd";
import { AvatarProps } from "antd/lib/avatar";
import React from "react";
import styled from "styled-components";
import { ReactComponent as AnonymousIcon } from "./anonymous.svg";

const StyledAvatar = styled(AntdAvatar)`
  background-color: #f4f5f4;
  flex-shrink: 0;

  .ant-avatar-string {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`;

interface Props extends Omit<AvatarProps, "src"> {
  name?: string;
  src?: string | null;
}

const Avatar: React.FC<Props> = ({ name, size = 40, src, ...extraProps }) => {
  return (
    <StyledAvatar alt={name} size={size} src={src || undefined} {...extraProps}>
      {!src && <AnonymousIcon />}
    </StyledAvatar>
  );
};

export default Avatar;
