import { Modal as BaseModal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import styled from "styled-components";

const Modal = styled(BaseModal)<ModalFuncProps>`
  color: #000000;

  .ant-modal-header {
    border-bottom: 0;
    padding-top: 40px;
  }

  .ant-modal-footer {
    border-top: 0;
    padding-bottom: 40px;
    text-align: center;
  }

  .ant-modal-footer > div {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
  }

  .ant-modal-footer .ant-btn {
    margin: 0 0 8px 0;
    border-color: transparent;
    box-shadow: none;
  }

  .ant-modal-footer .ant-btn.ant-btn-danger,
  .ant-modal-footer .ant-btn.ant-btn-success {
    border-color: inherit;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
`;

export default Modal;
