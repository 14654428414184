interface EnvironmentList {
  [environment: string]: {
    debugGoogleAnalytics: boolean;
    debugGoogleTagManager: boolean;
    debugTranslations: boolean;
    environment: string;
    isLocal: boolean;
    isProduction: boolean;
  };
}

const environments: EnvironmentList = {
  Local: {
    debugGoogleAnalytics: true,
    debugGoogleTagManager: true,
    debugTranslations: true,
    environment: "Local",
    isLocal: true,
    isProduction: false,
  },
  Dev: {
    debugGoogleAnalytics: true,
    debugGoogleTagManager: true,
    debugTranslations: false,
    environment: "Dev",
    isLocal: false,
    isProduction: true,
  },
  Staging: {
    debugGoogleAnalytics: true,
    debugGoogleTagManager: true,
    debugTranslations: false,
    environment: "Staging",
    isLocal: false,
    isProduction: true,
  },
  Prod: {
    debugGoogleAnalytics: false,
    debugGoogleTagManager: false,
    debugTranslations: false,
    environment: "Prod",
    isLocal: false,
    isProduction: true,
  },
};

const environmentCurrent = environments[process.env.REACT_APP_ENVIRONMENT || "Local"];

function fetchSetting(settingKey: string, isRequired = false) {
  const setting = process.env[settingKey];

  if (isRequired && !setting) {
    throw new Error(`Missing ${settingKey} in .env`);
  }

  return setting;
}

const exp = {
  apiBaseUrl: fetchSetting("REACT_APP_API_URL", true),
  defaultCountry: fetchSetting("REACT_APP_DEFAULT_COUNTRY") || "US",
  gaTrackingCode: fetchSetting("REACT_APP_GA_TRACKING_CODE", false),
  gtmCode: fetchSetting("REACT_APP_GTM_CODE", false),
  sentryDsn: fetchSetting("REACT_APP_SENTRY_DSN", false),
  translationsUrl: fetchSetting("REACT_APP_TRANSLATIONS_URL", true),
  ...environmentCurrent,
};

export default exp;
