import api from "api";
import { Model, getRoot, model, modelFlow, _async, _await } from "mobx-keystone";
import type RootStore from "./RootStore";
import { User } from "./models";

@model("covid/UserStore")
export default class UserStore extends Model({}) {
  @modelFlow
  emailCancel = _async(function* (this: UserStore, email: string) {
    const { data } = yield* _await(api.users.emailCancel(email));

    const rootStore = getRoot<RootStore>(this);
    rootStore.auth.user = new User(data);
  });

  @modelFlow
  emailVerify = _async(function* (this: UserStore, verifyToken: string) {
    const { data } = yield* _await(api.users.emailVerify(verifyToken));

    const rootStore = getRoot<RootStore>(this);
    rootStore.auth.user = new User(data);
  });
}
