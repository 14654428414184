import { InputNumber } from "antd";
import styled from "styled-components";

const NumberInput = styled(InputNumber)`
  align-items: center;
  background: #ffffff;
  border: none;
  box-shadow: none;
  display: flex;
  height: 32px;
  width: 75px;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    height: 100%;
    width: 100%;
  }

  .ant-input-number-input {
    border: 1px solid #205a9d;
    border-radius: 2px;
    height: 100%;
    padding: 0 10px 0 8px;

    &[value=""],
    &[value=""][disabled]:hover {
      border: 1px solid #bdbdbd;
    }

    &:focus,
    &:hover,
    &[disabled]:hover {
      border: 1px solid #205a9d;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(32, 89, 173, 0.2) !important;
    }

    &::placeholder {
      color: #bdbdbd;
      letter-spacing: 0.1px;
    }
  }
`;

export default NumberInput;
