/* eslint-disable max-classes-per-file */

import { Model, model, prop } from "mobx-keystone";
import { DashboardItem } from "./Dashboard";

export type SharingType = "all" | "survey";

export type Status = 0 | 1;

@model("covid/Following")
class Following extends Model({
  id: prop<identifier>(),
  avatar: prop<string | null>(),
  name: prop<string>(),
  fromUser: prop<number>(),
  shareType: prop<SharingType>(),
  status: prop<Status>(),
  summary: prop<DashboardItem[] | undefined>(),
  getEmailNotif: prop<boolean>(),
  getSmsNotif: prop<boolean>(),
}) {}

@model("covid/Follower")
class Follower extends Model({
  id: prop<identifier>(),
  avatar: prop<string | null>(),
  name: prop<string>(),
  shareType: prop<SharingType>(),
  status: prop<Status>(),
  toUser: prop<identifier>(),
}) {}

export { Following, Follower };
