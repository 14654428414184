import { APIPatientIn } from "api/patients";
import { computed } from "mobx";
import { model, Model, prop } from "mobx-keystone";
import moment from "moment";

export interface DashboardItem {
  date: string;
  value?: number;
  severity: number;
}

export interface DashboardDetail {
  data: DashboardItem[];
  min?: number;
  minSeverity: number;
  max?: number;
  maxSeverity: number;
  avg?: number;
  avgSeverity: number;
}

@model("covid/DashboardSummary")
export class DashboardSummary extends Model({
  date: prop<string>(),
  user: prop<APIPatientIn | null>(),
  temperature: prop<DashboardItem[]>(() => []),
  breathing: prop<DashboardItem[]>(() => []),
  cough: prop<DashboardItem[]>(() => []),
  socialInteraction: prop<DashboardItem[]>(() => []),
  otherSymptoms: prop<DashboardItem[]>(() => []),
  summary: prop<DashboardItem[]>(() => []),
}) {}

@model("covid/DashboardNotification")
export class DashboardNotification extends Model({
  id: prop<identifier>(),
  answer: prop<number>(),
  finishedAt: prop<string>(),
  jsonId: prop<string>(),
  name: prop<string>(),
  severity: prop<0 | 1 | 2 | 3>(0),
  userId: prop<identifier>(),
}) {
  @computed
  get finishedAtDate() {
    return moment(this.finishedAt);
  }
}
