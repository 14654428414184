import base, { APIList } from "./base";

export interface APIEventGeneric {
  [k: string]: identifier | number | string;
}

export interface APIEventSurveyCompleted extends APIEventGeneric {
  logId: identifier;
  surveyId: string;
}

export interface APIEventSharedProfile extends APIEventGeneric {
  userId: identifier;
}

export interface APIEventStartedFollowing extends APIEventGeneric {
  userId: identifier;
}

export interface APIEvent {
  id: identifier;
  user: identifier;
  created: string;
  type: "survey_completed" | "shared_profile" | "started_following";
  data: APIEventSurveyCompleted | APIEventSharedProfile | APIEventStartedFollowing;
}

export const list = (page = 1, limit = 20) => base.list<APIList<APIEvent>>("events/", page, limit);
