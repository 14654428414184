import { Button } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { makeWordBreakSafe } from "utils/string";

const StyledButton = styled(Button)`
  border: 1px solid #205a9d;
  letter-spacing: 0.3px;
  height: auto;
  max-width: 100%;
  padding: 7px 19px;
  text-align: right;
  white-space: pre-wrap;

  &:focus {
    border: 1px solid #205a9d;
    color: #333344;
  }
`;

interface Props<T> {
  defaultChecked?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  isChecked?: boolean;
  label: string;
  name?: string;
  onChange?: (value: T | undefined, name?: string) => void;
  value?: T;
}

function ButtonInput<T>({
  defaultChecked = false,
  disabled,
  icon,
  isChecked,
  label,
  onChange = () => {},
  name,
  value,
}: Props<T>) {
  const [isCheckedInternal, setIsCheckedInternal] = useState(defaultChecked);
  const isCheckedActual = isChecked === undefined ? isCheckedInternal : isChecked;

  const handleClick = () => {
    if (disabled) return;

    if (isChecked === undefined) {
      onChange(!isCheckedInternal ? value : undefined, name);
      setIsCheckedInternal((prevIsCheckedInternal) => !prevIsCheckedInternal);
    } else {
      onChange(!isChecked ? value : undefined, name);
    }
  };

  return (
    <StyledButton
      disabled={disabled}
      onClick={handleClick}
      type={isCheckedActual ? "primary" : "default"}
    >
      {makeWordBreakSafe(label)}
      {icon}
    </StyledButton>
  );
}

export default ButtonInput;
