import api from "api";
import {
  APISurveyLog,
  APISurveyLogsDetailSearchParams,
  APISurveyLogsListSearchParams,
} from "api/surveyLogs";
import type {
  APISurvey,
  APISurveyQuestion,
  APISurveysAnswerInput,
  APISurveySection,
  APISurveyStatusList,
} from "api/surveys";
import config from "config";
import { sortBy } from "lodash";
import { computed } from "mobx";
import {
  getRoot,
  getSnapshot,
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import moment, { Moment } from "moment";
import ReactGA from "react-ga";
import {
  Survey,
  SurveyAnswer,
  SurveyLog,
  SurveyQuestion,
  SurveyQuestionChoice,
  SurveyQuestionShowOn,
  SurveySection,
} from "./models";
import type { SurveyJsonId } from "./models/Survey";
import type RootStore from "./RootStore";

const defaultJsonId: SurveyJsonId = "GeneralD1";

@model("covid/SurveyStore")
export default class SurveyStore extends Model({
  logs: prop_mapObject(() => new Map<number, SurveyLog>()),
  dailyIntroQuestionId: prop<identifier | null | undefined>(),
  dailyJsonId: prop<SurveyJsonId>(defaultJsonId),
  statuses: prop<APISurveyStatusList>(() => ({})),
  surveys: prop_mapObject(() => new Map<number, Survey>()),
}) {
  buildSurveyQuestion(input: APISurveyQuestion) {
    const { choices: choicesData, showOn: showOnData, ...questionData } = input;

    const choices = choicesData.map((data) => new SurveyQuestionChoice(data));
    const showOn = showOnData.map((data) => new SurveyQuestionShowOn(data));

    return new SurveyQuestion({ choices, showOn, ...questionData });
  }

  buildSurveySection(input: APISurveySection) {
    const { questions: questionsData, ...sectionData } = input;

    const questions = questionsData.map((data) => this.buildSurveyQuestion(data));

    return new SurveySection({ questions, ...sectionData });
  }

  buildSurvey(input: APISurvey) {
    const { sections: sectionsData, ...surveyData } = input;

    const sections = sectionsData.map((data) => this.buildSurveySection(data));

    return new Survey({ sections, ...surveyData });
  }

  buildSurveyLog(input: APISurveyLog) {
    return new SurveyLog({
      id: input.id,
      date: input.date,
      finishedAt: input.finishedAt,
      shareToken: input.shareToken,
      shareUrl: input.shareUrl,
      startedAt: input.startedAt,
      surveyId: input.surveyId,
    });
  }

  @modelAction
  saveSurvey(input: APISurvey) {
    const survey = this.buildSurvey(input);
    this.surveys.set(input.id, survey);
    return survey;
  }

  @modelAction
  saveSurveyLog(input: APISurveyLog) {
    const log = this.buildSurveyLog(input);

    input.answers.forEach((answer) => {
      const answerNew = new SurveyAnswer(answer);
      log.setAnswer(answerNew.questionId, answerNew);
    });

    this.logs.set(log.id, log);

    return log;
  }

  @modelFlow
  detail = _async(function* (this: SurveyStore, id: identifier | string, isHistory = false) {
    const { data } = yield* _await(api.surveys.detail(id, isHistory));

    return this.saveSurvey(data);
  });

  @modelFlow
  detailByJsonId = _async(function* (this: SurveyStore, id: SurveyJsonId) {
    const { data } = yield* _await(api.surveys.getById(id));

    return this.saveSurvey(data);
  });

  @modelFlow
  answer = _async(function* (
    this: SurveyStore,
    survey: Survey,
    answer: SurveyAnswer,
    by: { date?: Moment; logId?: identifier }
  ) {
    const { date, logId } = by;
    const input: APISurveysAnswerInput = {
      answers: [getSnapshot(answer)],
    };

    if (date) {
      input.date = date.clone().locale("en").format("YYYY-MM-DD");
    }
    if (logId) {
      input.logId = logId;
    }

    const { data: output } = yield* _await(api.surveys.answer(survey.id, input));

    return this.saveSurveyLog(output);
  });

  @modelFlow
  submit = _async(function* (
    this: SurveyStore,
    survey: Survey,
    surveyLog: SurveyLog,
    by: { date?: Moment; logId?: identifier },
    isPaginated: boolean
  ) {
    const { date, logId } = by;
    const rootStore = getRoot<RootStore>(this);
    const input: APISurveysAnswerInput = {
      answers: survey.processAnswers(surveyLog, rootStore.settings, isPaginated),
    };

    if (date) {
      input.date = date.clone().locale("en").format("YYYY-MM-DD");
    }
    if (logId) {
      input.logId = logId;
    }

    const { data: output } = yield* _await(api.surveys.answer(survey.id, input));

    if (config.gaTrackingCode) {
      ReactGA.event({
        category: "Survey",
        action: "Answered survey",
        label: survey.title,
        value: survey.id,
      });
    }

    return this.saveSurveyLog(output);
  });

  @modelFlow
  checkAnswers = _async(function* (this: SurveyStore, surveyId: identifier | string, date: Moment) {
    const { data } = yield* _await(api.surveys.getSurveyAnswers(surveyId, date));

    return this.saveSurveyLog(data);
  });

  @modelFlow
  checkSurveyStatuses = _async(function* (this: SurveyStore) {
    try {
      const {
        data: { dailyIntroQuestionId, dailyJsonId, statuses },
      } = yield* _await(api.surveys.statuses());

      this.dailyIntroQuestionId = dailyIntroQuestionId;
      this.dailyJsonId = dailyJsonId;
      this.statuses = statuses;

      return statuses;
    } catch (e) {
      _await(api.handleError(e, { all: false }));
      return {};
    }
  });

  @modelFlow
  detailLog = _async(function* (this: SurveyStore, logId: identifier | string) {
    const { data } = yield* _await(api.surveyLogs.detail(logId, { paginate: true }));

    return this.saveSurveyLog(data);
  });

  @modelFlow
  detailLogPaginated = _async(function* (
    this: SurveyStore,
    logId: identifier | string,
    searchParams: APISurveyLogsDetailSearchParams = {}
  ) {
    const { data, includes } = yield* _await(
      api.surveyLogs.detail(logId, { limit: 7, ...searchParams, paginate: true })
    );

    const log = this.saveSurveyLog(data);

    const nextLogs = includes.nextLogs.map((input) => this.saveSurveyLog(input));
    const prevLogs = includes.prevLogs.map((input) => this.saveSurveyLog(input));

    return {
      hasNext: includes.hasNext,
      hasPrev: includes.hasPrev,
      log,
      nextLogs,
      prevLogs,
    };
  });

  @modelFlow
  detailLogByShareToken = _async(function* (this: SurveyStore, shareToken: string) {
    const { data, includes } = yield* _await(api.surveyLogs.detailByShareToken(shareToken));

    let nextDate;
    if (includes.nextDate) {
      nextDate = moment(includes.nextDate);
    }

    return {
      log: this.saveSurveyLog(data),
      nextDate,
      survey: this.saveSurvey(includes.survey),
    };
  });

  @modelFlow
  listLogs = _async(function* (
    this: SurveyStore,
    surveyId: identifier | string,
    page: number,
    searchParams: APISurveyLogsListSearchParams = {}
  ) {
    const { data, includes, next, previous } = yield* _await(
      api.surveyLogs.list(surveyId, page, 10, searchParams)
    );

    const logs = data.map((input) => {
      return this.saveSurveyLog(input);
    });

    let nextDate;
    if (includes.nextDate) {
      nextDate = moment(includes.nextDate);
    }

    return { hasNext: !!next, hasPrev: !!previous, logs, nextDate };
  });

  @modelFlow
  shareLog = _async(function* (this: SurveyStore, logId: identifier | string) {
    const { data } = yield* _await(api.surveyLogs.share(logId));

    return this.saveSurveyLog(data);
  });

  @modelFlow
  unshareLog = _async(function* (this: SurveyStore, logId: identifier | string) {
    const { data } = yield* _await(api.surveyLogs.unshare(logId));

    return this.saveSurveyLog(data);
  });

  @computed
  get dailyId() {
    const dailyStatus = this.statuses[this.dailyJsonId];
    return dailyStatus?.survey.id;
  }

  @modelFlow
  checkSurveyStatuses2 = _async(function* (this: SurveyStore) {
    const { data } = yield* _await(api.surveys.list());
    return data;
  });

  @computed
  get statusesList() {
    return sortBy(
      Object.keys(this.statuses).map((jsonId) => this.statuses[jsonId as SurveyJsonId]!),
      "order"
    );
  }
}
