import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { DashboardItem } from "stores/models/Dashboard";
import styled from "styled-components";
import { severityToColor } from "utils/dashboard";
import Avatar from "./Avatar";

type Props = {
  avatar?: string | null;
  href?: string;
  name: string | React.ReactNode | React.ReactText;
  patientName?: string;
  sevenDaySummary?: DashboardItem[];
  subtitle?: string | React.ReactNode | React.ReactText;
};

const Container = styled.div`
  display: flex;
`;

const NameContainer = styled.div`
  flex: 1;
  padding: 0 22px;
`;

const Name = styled.div`
  color: #333344;
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.p`
  color: #666974;
  font-size: 12px;
  line-height: 1;
  margin: 4px 0;
`;

const SevenDaysContainer = styled.div`
  border: none;
  display: flex;
  flex-direction: row;
`;

const OneDay = styled.div`
  background: ${severityToColor(0)};
  border-right: 2px solid white;
  font-size: 10px;
  height: 6px !important;
  padding-top: 6px;
  text-align: center;
  width: 25px;
  max-width: 25px;
  margin-bottom: 16px;

  :first-child {
    border-radius: 2px 0 0 2px;
  }

  :last-child {
    border-radius: 0 2px 2px 0;
    border-right: none;
  }

  &.normal {
    background: ${severityToColor(1)};
  }
  &.moderate {
    background: ${severityToColor(2)};
  }
  &.severe {
    background: ${severityToColor(3)};
  }
`;

const toClassName = (value: number) => {
  switch (value) {
    case 1:
      return "normal";
    case 2:
      return "moderate";
    case 3:
      return "severe";
    default:
      return "no-data";
  }
};

const NamePlate: React.FC<Props> = ({
  avatar,
  href,
  name,
  patientName,
  sevenDaySummary,
  subtitle,
}) => {
  const renderSevenDay = () => {
    if (sevenDaySummary) {
      return (
        <SevenDaysContainer>
          {sevenDaySummary.map(({ date, severity }) => (
            <OneDay key={date} className={toClassName(severity)}>
              {moment(date).format("dd")}
            </OneDay>
          ))}
        </SevenDaysContainer>
      );
    }
    return null;
  };

  return (
    <Container>
      {href ? (
        <Link to={href}>
          <Avatar name={patientName} src={avatar} />
        </Link>
      ) : (
        <Avatar name={patientName} src={avatar} />
      )}
      <NameContainer>
        {href ? (
          <Link to={href}>
            <Name>{name}</Name>
          </Link>
        ) : (
          <Name>{name}</Name>
        )}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {renderSevenDay()}
      </NameContainer>
    </Container>
  );
};

export default observer(NamePlate);
