import base, { APIDetail, APIList } from "./base";

export interface APIStudy {
  id: identifier;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  logo: string;
  survey: identifier;
  consentForm: identifier;
  isEligible: boolean;
  isOpen: boolean;
  hasConsentedInApp: boolean;
  hasConsented: boolean;
}

export interface APIConsent {
  id: identifier;
  user: identifier;
  study: identifier;
  appConsent: boolean;
  emailConsent: boolean;
  date: Date;
}

export interface APIStudiesDetail extends APIDetail<APIStudy> {}
export const detail = (id: identifier | string) => base.get<APIStudiesDetail>(`studies/${id}/`);

export interface APIStudiesList extends APIList<APIStudy> {}
export const list = (page = 1, searchParams = {}) =>
  base.list<APIStudiesList>("studies/", page, 0, { searchParams });

export const inAppConsent = (id: identifier | string) => base.post<APIStudiesDetail>(`studies/${id}/in-app-consent/`);

export const resendEmailConsent = (id: identifier | string) => base.post(`studies/${id}/resend-email-consent/`);

export const unconsent = (id: identifier | string) => base.post<APIStudiesDetail>(`studies/${id}/unconsent/`);
