import { Dropdown, Menu } from "antd";
import { ReactComponent as Globe } from "assets/images/globe.svg";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  changeLanguage,
  getLanguageCodeFormatted,
  getLanguagePartFromCode,
  languages,
} from "utils/i18n";

const LanguageContainer = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

const LanguageIcon = styled(Globe)`
  height: 26px;
  margin-right: 2px;
  padding: 4px;
  width: auto;
`;

const LanguageMenu = styled(Menu)`
  min-width: 200px;

  .ant-dropdown-menu-item-selected {
    color: #333344;
    font-weight: 600;
  }
`;

const LanguageText = styled.span``;

interface Props {
  className?: string;
}

const LanguageSelector: React.FC<Props> = ({ className }) => {
  const { i18n } = useTranslation();

  const languagesSupported = Object.keys(languages);
  let languageValue = i18n.language.toLocaleLowerCase();
  if (!languagesSupported.includes(languageValue)) {
    const languagePartFromCode = getLanguagePartFromCode(languageValue);
    languageValue = languagesSupported.includes(languagePartFromCode) ? languagePartFromCode : "en";
  }

  useEffect(() => {
    changeLanguage(languageValue);
  }, [languageValue]);

  const handleSelect = (key: string) => {
    changeLanguage(key);
  };

  return (
    <Dropdown
      overlay={
        <LanguageMenu selectedKeys={[languageValue]}>
          {Object.keys(languages).map((key) => (
            <Menu.Item key={key} onClick={() => handleSelect(key)}>
              {languages[key]}
            </Menu.Item>
          ))}
        </LanguageMenu>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <LanguageContainer className={className}>
        <LanguageIcon />
        <LanguageText>{getLanguageCodeFormatted(languageValue)}</LanguageText>
      </LanguageContainer>
    </Dropdown>
  );
};

export default LanguageSelector;
