import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { history } from "services";

export const initializeGA = (gaTrackingCode: string, debug: boolean) => {
  console.log("Initializing Google Analytics...", { debug });

  ReactGA.initialize(gaTrackingCode, { debug });

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  // Initial page load.
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const initializeGTM = (gtmCode: string, debug: boolean) => {
  console.log("Initializing Google Tag Manager...", { debug });
  TagManager.initialize({
    gtmId: gtmCode,
    events: {
      sendUserInfo: "userInfo",
    },
  });

  history.listen((location) => {
    TagManager.dataLayer({
      dataLayer: {
        page: location.pathname,
        event: "pageview",
      },
    });
  });

  // Initial page load.
  TagManager.dataLayer({
    dataLayer: {
      page: window.location.pathname,
      event: "pageview",
    },
  });
};
