import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { languages } from "utils/i18n";
import config from "./config";

let loadPath;
if (config.debugTranslations) {
  loadPath = (lngs: string[]) => {
    if (lngs.length === 1 && lngs[0] === "en") {
      return "/locales/{{lng}}/{{ns}}.json";
    } else {
      return config.translationsUrl;
    }
  };
} else {
  loadPath = config.translationsUrl;
}

// You must explicitly add the base language (e.g. zh-hans requires zh)
const whitelist = [...Object.keys(languages), "zh"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath,
      parse: (data: string) => JSON.parse(data),
    },
    debug: config.debugTranslations,
    fallbackLng: "en",
    returnEmptyString: false,
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    nonExplicitWhitelist: true,
    whitelist,
  });

export default i18n;
