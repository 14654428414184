import config from "config";
import i18next from "i18next";
import ky from "ky";
import { flash } from "services";
import * as auth from "./auth";
import { apiConfig } from "./base";
import * as dashboard from "./dashboard";
import * as events from "./events";
import * as feedback from "./feedback";
import * as followers from "./followers";
import * as patients from "./patients";
import * as settings from "./settings";
import * as studies from "./studies";
import * as surveyLogs from "./surveyLogs";
import * as surveys from "./surveys";
import * as users from "./users";

export interface APIValidationErrors {
  [key: string]: APIValidationErrors | string[];
}

export type APIErrorCode =
  | "auth/invalid_token"
  | "auth/missing_user"
  | "authentication_failed"
  | "followers/mobile_number_own"
  | "followers/request_exists"
  | "generic/authentication"
  | "generic/deletion"
  | "generic/missing"
  | "generic/network"
  | "generic/permission"
  | "generic/syntax"
  | "generic/throttled"
  | "generic/timeout"
  | "generic/validation"
  | "generic/web"
  | "settings/wrong_mobile_number"
  | "surveys/hidden"
  | "users/email_exists"
  | "users/email_verify_done"
  | "users/email_verify_expired"
  | "users/mobile_number_exists"
  | "users/country_not_allowed";

export interface APIErrorResponse {
  code: APIErrorCode;
  description: string;
  detail?: APIValidationErrors | string | null;
  message?: string;
}

function processValidationDetails(
  validationDetail: APIValidationErrors,
  key: string,
  flashError: boolean
) {
  const detail = validationDetail[key];

  if (Array.isArray(detail)) {
    return detail.map((text) => {
      const message = i18next.t(`errors.validation.${text.replace(/\.+$/, "")}`);

      if (flashError) {
        flash({ id: message, message, type: "error" });
      }

      return message;
    });
  } else {
    const nestedDetail: APIValidationErrors = {};

    Object.keys(detail).forEach((nestedKey) => {
      nestedDetail[nestedKey] = processValidationDetails(detail, nestedKey, flashError);
    });

    return nestedDetail;
  }
}

async function handleError(
  error: Error,
  flashErrors?: { all?: boolean; generic?: boolean; validationDetails?: boolean },
  customMessages: {
    [k in APIErrorCode]?: string | React.ReactNode;
  } = {}
): Promise<APIErrorResponse> {
  const flashErrorsActual = { generic: true, validationDetails: false, ...flashErrors };
  if (flashErrors?.all != null) {
    flashErrorsActual.generic = flashErrors.all;
    flashErrorsActual.validationDetails = flashErrors.all;
  }

  let errorResponse: APIErrorResponse;
  let isSyntaxError = false;

  if (error instanceof ky.HTTPError) {
    errorResponse = await error.response.json();

    if (errorResponse.code === "generic/missing") {
      // no-op
    } else if (errorResponse.code === "generic/throttled") {
      const validationDetail = (errorResponse.detail as any) as number;
      flash({
        id: errorResponse.code,
        message: i18next.t("errors.generic/throttled", { count: Math.floor(validationDetail) }),
        type: "error",
      });
      errorResponse.detail = {
        throttle: [validationDetail.toString()],
      };
    } else if (errorResponse.code === "generic/validation") {
      const validationDetail = errorResponse.detail as APIValidationErrors ?? {};
      const validationErrors: APIValidationErrors = {};

      Object.keys(validationDetail).forEach((key) => {
        validationErrors[key] = processValidationDetails(
          validationDetail,
          key,
          flashErrorsActual.validationDetails
        );
      });

      if (!flashErrorsActual.validationDetails) {
        errorResponse.message = errorResponse.message ?? i18next.t("errors.validation/generic");

        flash({
          id: errorResponse.code,
          message: customMessages[errorResponse.code] || errorResponse.message,
          type: "error",
        });
      }

      errorResponse.detail = validationErrors;
    } else if (flashErrorsActual.generic) {
      const messageKey = `errors.${errorResponse.code}`;
      let message = i18next.t(messageKey);
      if (!message || message === messageKey) {
        message = i18next.t("errors.generic/api");
      }

      errorResponse.message = message;

      flash({
        id: errorResponse.code,
        message: customMessages[errorResponse.code] || message,
        type: "error",
      });
    }
  } else if (error instanceof ky.TimeoutError) {
    errorResponse = {
      code: "generic/timeout",
      description: error.message,
    };

    errorResponse.message = i18next.t("errors.generic/timeout");

    flash({
      id: "generic/timeout",
      message: customMessages["generic/timeout"] || errorResponse.message,
      type: "error",
    });
  } else if (error instanceof TypeError) {
    errorResponse = {
      code: "generic/network",
      description: error.message,
    };

    errorResponse.message = i18next.t("errors.generic/network");

    flash({
      id: "generic/network",
      message: customMessages["generic/network"] || errorResponse.message,
      type: "error",
    });
  } else {
    errorResponse = {
      code: "generic/syntax",
      description: error.message,
    };

    errorResponse.message = i18next.t("errors.generic/web");

    flash({
      id: "generic/syntax",
      message: customMessages["generic/syntax"] || errorResponse.message,
      type: "error",
    });

    isSyntaxError = true;
  }

  console.error(errorResponse);
  if (!config.isProduction && isSyntaxError) console.trace();
  return errorResponse;
}

const exp = {
  auth,
  config: apiConfig,
  dashboard,
  events,
  feedback,
  followers,
  handleError,
  patients,
  settings,
  studies,
  surveyLogs,
  surveys,
  users,
};

export default exp;
