import config from "config";

const urls = {
  rootPath: "/",

  // logged-in
  aboutPath: "/about",
  activityPath: "/activity",
  contactPath: "/contact",
  dashboardPath: "/dashboard",
  feedbackPath: "/feedback",
  followersPath: "/followers",
  followingPath: "/following",
  followingActivityPath: (id: identifier | ":id") => `/following/${id}/activity`,
  followingHomePath: (id: identifier | ":id") => `/following/${id}`,
  followingDashboardPath: (id: identifier | ":id") => `/following/${id}/dashboard`,
  profileEmailVerificationPath: (token?: string) =>
    token ? `/users/emails/?token=${token}` : "/users/emails/",
  profileCreatePath: "/users/new",
  profileDetailPath: (id: identifier | ":id") => `/users/${id}`,
  settingsPath: "/settings",
  settingsShortcutPath: "/stop",
  studiesPath: "/studies",
  studiesConsentPath: (studyId: identifier | ":studyId") => `/studies/${studyId}/consent`,
  studiesDetailPath: (studyId: identifier | ":studyId") => `/studies/${studyId}`,
  studiesJoinPath: (studyId: identifier | ":studyId") => `/studies/${studyId}/join`,
  surveysPath: "/surveys",
  surveysDailyShortcutPath: "/s",
  surveysAnswerPath: (surveyId: identifier | ":surveyId") => `/surveys/${surveyId}/answer`,
  surveysAnswerResultPath: (surveyId: identifier | ":surveyId") =>
    `/surveys/${surveyId}/answer/result`,
  surveysHistoryPath: (surveyId: identifier | ":surveyId", logId?: identifier | ":logId") =>
    logId ? `/surveys/${surveyId}/history/${logId}` : `/surveys/${surveyId}/history`,
  surveysHistorySharePath: (shareToken: string | ":shareToken") => `/sl/${shareToken}`,

  // guest
  loginPath: "/login",
  logoutPath: "/logout",
  registerPath: "/register",

  // always available
  privacyPolicyPath: "/privacy",
  termsOfUsePath: "/terms",

  // api
  api: {
    uploadAvatarPath: `${config.apiBaseUrl}/profiles/picture/`,
  },
};

export default urls;
