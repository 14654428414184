import i18n from "i18next";
import moment from "moment";

export const languages: { [key: string]: string } = {
  en: "English (EN)",
  es: "Español (ES)",
  fr: "Français (FR)",
  tl: "Filipino (FIL)",
  ar: "العربية (AR)",
  de: "Deutsch (DE)",
  hi: "हिन्दी (HI)",
  it: "Italiano (IT)",
  ms: "Bahasa Melayu (MS)",
  pt: "Português (PT)",
  "pt-br": "Português brasileiro (PT-BR)",
  ru: "Русский (RU)",
  sw: "Kiswahili (SW)",
  "zh-hans": "中文简体 (ZH-CN)",
  cs: "Čeština (CS)",
  ja: "日本語 (JA)",
  ind: "Bahasa Indonesia (ID)",
  bn: "বাংলা (BN)",
  uk: "українська мова (UK)",
  ur: "اُردُو (UR)",
};

export async function changeLanguage(lng: string) {
  switch (lng) {
    case "tl":
      moment.locale("tl-ph");
      break;
    case "ind":
      moment.locale("id");
      break;
    case "zh-hans":
      moment.locale("zh-cn");
      break;
    default:
      moment.locale(lng);
      break;
  }

  await i18n.changeLanguage(lng);
}

export function getLanguageCodeFormatted(lng: string) {
  let languageCodeForDisplay = lng.toUpperCase();
  if (languageCodeForDisplay === "ZH-HANS") {
    languageCodeForDisplay = "ZH-CN";
  } else if (languageCodeForDisplay === "TL") {
    languageCodeForDisplay = "FIL";
  }

  return languageCodeForDisplay;
}

export function getLanguagePartFromCode(code: string) {
  if (!code || code.indexOf("-") < 0) return code;

  return code.split("-")[0];
}
