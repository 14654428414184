/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */

import type { APISurveyQuestionAnswer } from "api/surveys";
import { find, intersection, keyBy } from "lodash";
import { computed } from "mobx";
import {
  findParent,
  getSnapshot,
  Model,
  model,
  modelAction,
  prop,
  prop_mapObject,
} from "mobx-keystone";
import moment, { Moment } from "moment";
import SettingsStore from "../SettingsStore";

export type SurveyCadence = "once" | "daily" | "weekly" | "biweeekly" | "monthly";

export type ComparisonTypes = "none" | "eq" | "neq" | "gt" | "gte" | "lt" | "lte";

export type SurveyQuestionType =
  | "boolean"
  | "button"
  | "checkbox"
  | "checkbox_add"
  | "checkbox_add_none"
  | "checkbox_none"
  | "date"
  | "dropdown"
  | "horizontal_slider"
  | "horizontal_slider_units"
  | "horizontal_slider_units_temperature"
  | "number"
  | "number_units"
  | "radio"
  | "textbox"
  | "vertical_slider"
  | "vertical_slider_units";

export type SurveyJsonId =
  | "AsthmaB1"
  | "AsthmaW1"
  | "DEBUG"
  | "GeneralB1"
  | "GeneralB40.1"
  | "GeneralD1"
  | "GeneralW1"
  | "RAND1";

@model("covid/SurveyAnswer")
class SurveyAnswer extends Model({
  answer: prop(""),
  choices: prop<identifier[]>(() => []),
  questionId: prop<identifier>(),
  isSkipped: prop(false),
}) {
  static build(question: SurveyQuestion, settings?: SettingsStore, isSkipped?: boolean) {
    switch (question.type) {
      case "horizontal_slider":
      case "vertical_slider": {
        const answer = question.minRange ? `${parseFloat(question.minRange)}` : "";
        return new SurveyAnswer({ questionId: question.id, answer, choices: [], isSkipped });
      }

      case "horizontal_slider_units":
      case "horizontal_slider_units_temperature": {
        const units: {
          [k: number]: { unit: string; min: number; max: number; stepCount: number };
        } = {};

        question.choices.forEach((choice) => {
          const [unit, otherValues] = choice.text.split(":");
          const [range, step] = otherValues.split("/");
          const [minRange, maxRange] = range.split("-");
          if (maxRange && minRange && step) {
            units[choice.id] = {
              unit,
              min: parseFloat(minRange),
              max: parseFloat(maxRange),
              stepCount: parseFloat(step),
            };
          }
        });

        let celsiusChoice;
        let fahrenheitChoice;
        if (question.type === "horizontal_slider_units_temperature") {
          celsiusChoice = find(question.choices, (item) => item.text.includes("ºC"));
          fahrenheitChoice = find(question.choices, (item) => item.text.includes("ºF"));
        }

        let answer;
        let choice: SurveyQuestionChoice | undefined;
        if (celsiusChoice && fahrenheitChoice && settings) {
          if (settings.temperatureUnits === "c") {
            answer = 36.4;
            choice = celsiusChoice;
          } else {
            answer = 97.6;
            choice = fahrenheitChoice;
          }
        } else {
          [choice] = question.choices;
          answer = units[choice.id].min;
        }

        return new SurveyAnswer({
          questionId: question.id,
          answer: `${answer}`,
          choices: [choice.id],
          isSkipped,
        });
      }

      default:
        return new SurveyAnswer({ questionId: question.id, answer: "", choices: [], isSkipped });
    }
  }
}

@model("covid/SurveyLog")
class SurveyLog extends Model({
  id: prop<identifier>(),
  answers: prop_mapObject(() => new Map<identifier, SurveyAnswer>()),
  date: prop<string>(),
  finishedAt: prop<timestamp | null>(),
  shareToken: prop<string | null>(null),
  shareUrl: prop<string | null>(null),
  startedAt: prop<timestamp | null>(),
  surveyId: prop<identifier>(),
}) {
  static build(date: Moment, survey: Survey) {
    return new SurveyLog({
      id: 0,
      date: date.clone().locale("en").format("YYYY-MM-DD"),
      finishedAt: null,
      startedAt: null,
      surveyId: survey.id,
    });
  }

  @modelAction
  setAnswer(questionId: identifier, answer: SurveyAnswer) {
    this.answers.set(questionId, answer);
  }

  @computed
  get answerList() {
    return Object.fromEntries(this.answers);
  }

  @computed
  get finishedDate() {
    return this.finishedAt ? moment(this.finishedAt) : null;
  }

  @computed
  get finished() {
    return !!this.finishedAt;
  }

  @computed
  get startedDate() {
    return this.startedAt ? moment(this.startedAt) : null;
  }

  @computed
  get started() {
    return !!this.startedAt;
  }
}

@model("covid/SurveyQuestionChoice")
class SurveyQuestionChoice extends Model({
  id: prop<identifier>(),
  text: prop<string>(),
}) {}

@model("covid/SurveyQuestionShowOn")
class SurveyQuestionShowOn extends Model({
  choices: prop<identifier[]>(() => []),
  isAnswered: prop(false),
  targetQuestion: prop<identifier>(),
  useChoices: prop(true),
  comparisonType: prop<ComparisonTypes>(),
  comparisonReference: prop<identifier>(),
}) {}

@model("covid/SurveyQuestion")
class SurveyQuestion extends Model({
  id: prop<identifier>(),
  choices: prop<SurveyQuestionChoice[]>(() => []),
  maxRange: prop<decimalstring | null>(),
  minRange: prop<decimalstring | null>(),
  showOn: prop<SurveyQuestionShowOn[]>(() => []),
  step: prop<decimalstring | null>(),
  text: prop<string>(),
  type: prop<SurveyQuestionType>(),
}) {
  @computed
  get section() {
    return findParent(this, (node) => node instanceof SurveySection) as SurveySection;
  }

  isVisible(surveyLog: SurveyLog) {
    for (const showOn of this.showOn) {
      const answer =
        surveyLog.answerList[showOn.targetQuestion] ||
        new SurveyAnswer({
          questionId: showOn.targetQuestion,
        });

      if (showOn.useChoices) {
        if (showOn.isAnswered && answer.isSkipped) {
          return false;
        }

        if (showOn.choices.length > 0 && intersection(answer.choices, showOn.choices).length === 0) {
          return false;
        }
      } else {
        let shouldBeShown = true;
        const currentValue = isNaN(parseInt(answer.answer)) ? 0 : parseInt(answer.answer);
        switch (showOn.comparisonType) {
          case "eq":
            shouldBeShown = currentValue === showOn.comparisonReference;
            break;
          case "neq":
            shouldBeShown = currentValue !== showOn.comparisonReference;
            break;
          case "gt":
            shouldBeShown = currentValue > showOn.comparisonReference;
            break;
          case "gte":
            shouldBeShown = currentValue >= showOn.comparisonReference;
            break;
          case "lt":
            shouldBeShown = currentValue < showOn.comparisonReference;
            break;
          case "lte":
            shouldBeShown = currentValue <= showOn.comparisonReference;
            break;
          default:
            break;
        }
        if ( !shouldBeShown ) {
          return false;
        }
      }
    }

    return true;
  }

  isHidden(surveyLog: SurveyLog) {
    return !this.isVisible(surveyLog);
  }
}

@model("covid/SurveySection")
class SurveySection extends Model({
  id: prop<identifier>(),
  title: prop<string>(),
  questions: prop<SurveyQuestion[]>(() => []),
}) {
  @computed
  get survey() {
    return findParent(this, (node) => node instanceof Survey) as Survey;
  }

  hiddenQuestions(surveyLog: SurveyLog) {
    return this.questions.filter((question) => question.isHidden(surveyLog));
  }

  visibleQuestions(surveyLog: SurveyLog) {
    return this.questions.filter((question) => question.isVisible(surveyLog));
  }
}

@model("covid/Survey")
class Survey extends Model({
  id: prop<identifier>(),
  cadence: prop<SurveyCadence>(),
  jsonId: prop<SurveyJsonId>(),
  sections: prop<SurveySection[]>(() => []),
  title: prop<string>(),
  study: prop<identifier>(),
  consentFor: prop<identifier>(),
}) {
  @computed
  get questions() {
    let questions: SurveyQuestion[] = [];

    this.sections.forEach((section) => {
      questions = questions.concat(section.questions);
    });

    return questions;
  }

  currentPage(surveyLog: SurveyLog) {
    let page = 0;

    for (const section of this.sections) {
      for (const question of section.visibleQuestions(surveyLog)) {
        if (!surveyLog.answerList[question.id]) return page;
        page += 1;
      }
    }

    return page;
  }

  hiddenQuestions(surveyLog: SurveyLog) {
    let hiddenQuestions: SurveyQuestion[] = [];

    this.sections.forEach((section) => {
      hiddenQuestions = hiddenQuestions.concat(section.hiddenQuestions(surveyLog));
    });

    return hiddenQuestions;
  }

  visibleQuestions(surveyLog: SurveyLog) {
    let visibleQuestions: SurveyQuestion[] = [];

    this.sections.forEach((section) => {
      visibleQuestions = visibleQuestions.concat(section.visibleQuestions(surveyLog));
    });

    return visibleQuestions;
  }

  processAnswers(surveyLog: SurveyLog, settings?: SettingsStore, isPaginated?: boolean) {
    const hiddenQuestions = keyBy(this.hiddenQuestions(surveyLog), "id");
    const answersProcessed: { [key: string]: APISurveyQuestionAnswer } = {};

    this.questions.forEach((question) => {
      const hasAnswer = isPaginated || question.id in surveyLog.answerList;
      const isHiddenQuestion = question.id in hiddenQuestions;

      if (hasAnswer && !isHiddenQuestion) {
        if (!isPaginated && hasAnswer) {
          answersProcessed[question.id] = getSnapshot(surveyLog.answerList[question.id]);
        }

        return;
      }

      switch (question.type) {
        case "checkbox_add_none":
        case "checkbox_none":
        case "horizontal_slider":
        case "horizontal_slider_units":
        case "horizontal_slider_units_temperature":
        case "vertical_slider": {
          if (!hasAnswer || isHiddenQuestion) {
            answersProcessed[question.id] = getSnapshot(SurveyAnswer.build(question, settings));
          }

          break;
        }

        default:
          if (isHiddenQuestion) {
            answersProcessed[question.id] = {
              ...getSnapshot(SurveyAnswer.build(question, settings)),
              isSkipped: true,
            };
          }
      }
    });

    return Object.values(answersProcessed);
  }
}

export {
  Survey,
  SurveyAnswer,
  SurveyLog,
  SurveyQuestion,
  SurveyQuestionChoice,
  SurveyQuestionShowOn,
  SurveySection,
};
