import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import urls from "urls";

type Props = RouteProps & {
  isLoggedIn?: boolean;
};

const GuestRoute: React.FC<Props> = ({ children, isLoggedIn, ...rest }) => {
  if (!isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  }
  return <Redirect to={urls.rootPath} />;
};

export default GuestRoute;
