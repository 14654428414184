import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import urls from "urls";

type Props = RouteProps & {
  isLoggedIn?: boolean;
  isProfileComplete?: boolean;
};

const AuthenticatedRoute: React.FC<Props> = ({
  children,
  isLoggedIn,
  isProfileComplete,
  ...rest
}) => {
  const location = useLocation();

  if (!isLoggedIn) {
    return <Redirect to={`${urls.loginPath}?next=${location.pathname}${location.search}`} />;
  }
  if (!isProfileComplete) {
    return <Redirect to={urls.profileCreatePath} />;
  }
  return <Route {...rest}>{children}</Route>;
};

export default AuthenticatedRoute;
