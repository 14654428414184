import api from "api";
import { model, Model, modelFlow, prop, prop_mapObject, _async, _await } from "mobx-keystone";
import moment from "moment";
import { Event } from "./models";

const sortDesc = (a: Event, b: Event) => {
  const aDate = moment(a.created);
  const bDate = moment(b.created);
  if (aDate.isBefore(bDate)) {
    return 1;
  } else if (aDate.isAfter(bDate)) {
    return -1;
  }
  return 0;
};

@model("covid/EventStore")
class EventStore extends Model({
  events: prop_mapObject(() => new Map<identifier, Event>()),
  isLoading: prop(true),
  total: prop(0),
}) {
  private page = 1;

  hasNext = true;

  get eventsList() {
    return Array.from(this.events, ([, v]) => v).sort(sortDesc);
  }

  @modelFlow
  loadEvents = _async(function* (this: EventStore, restart = false) {
    if (restart) {
      this.page = 1;
      this.events.clear();
      this.hasNext = true;
    }

    if (!this.hasNext) {
      return;
    }

    this.isLoading = true;

    const { count, data, next } = yield* _await(api.events.list(this.page));

    this.total = count;
    if (next) {
      this.page += 1;
    } else {
      this.hasNext = false;
    }

    data.forEach((item) => {
      const eventData = new Event(item);
      this.events.set(eventData.id, eventData);
    });

    this.isLoading = false;
  });
}

export default EventStore;
