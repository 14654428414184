import api from "api";
import { computed } from "mobx";
import {
  fromSnapshot,
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { Follower, Following } from "./models";
import { SharingType } from "./models/Follower";

const sortingFunction = (a: Following | Follower, b: Following | Follower) => {
  if (a.status < b.status) return -1;
  if (a.status > b.status) return 1;
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

@model("covid/FollowerStore")
export default class FollowerStore extends Model({
  followers: prop_mapObject(() => new Map<number, Follower>()),
  following: prop_mapObject(() => new Map<number, Following>()),
  followersTotal: prop<number>(),
  followingTotal: prop<number>(),
  maxFollowers: prop<number>(),
}) {
  get followersList() {
    return Array.from(this.followers, ([k, v]) => v).sort(sortingFunction);
  }

  get followingList() {
    return Array.from(this.following, ([k, v]) => v).sort(sortingFunction);
  }

  @computed
  get isSMSNotifMaxed() {
    return Array.from(this.following, ([k, v]) => v).filter((v) => v.getSmsNotif).length >= 3;
  }

  @computed
  get hasMaxFollowers() {
    return this.followers.size >= this.maxFollowers;
  }

  @modelFlow
  getFollowers = _async(function* (this: FollowerStore) {
    const { count, maxFollowers, data } = yield* _await(api.followers.getFollowers());
    this.followersTotal = count;
    this.maxFollowers = maxFollowers;
    data.forEach((follower) => {
      const followerData = fromSnapshot<Follower>(follower as Follower);
      this.followers.set(follower.id, followerData);
    });
  });

  @modelFlow
  getFollowing = _async(function* (this: FollowerStore) {
    const { count, data } = yield* _await(api.followers.getFollowing());
    this.followingTotal = count;
    data.forEach((following) => {
      const followingData = fromSnapshot<Following>(following as Following);
      this.following.set(following.id, followingData);
    });
  });

  @modelFlow
  invite = _async(function* (this: FollowerStore, detail = {}) {
    const { data } = yield* _await(api.followers.invite(detail));
    const followerData = fromSnapshot<Follower>(data as Follower);
    this.followersTotal += 1;
    this.followers.set(data.id, followerData);
  });

  @modelFlow
  acceptRequest = _async(function* (this: FollowerStore, id: number) {
    try {
      const { data } = yield* _await(api.followers.updateFollowing(id, { status: 1 }));
      const followingData = fromSnapshot<Following>(data as Following);
      this.following.set(id, followingData);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  deleteFollowing = _async(function* (this: FollowerStore, id: number) {
    try {
      yield* _await(api.followers.deleteFollowRequest(id));
      this.followingTotal -= 1;
      this.following.delete(id);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  deleteFollower = _async(function* (this: FollowerStore, id: number) {
    try {
      yield* _await(api.followers.deleteFollowRequest(id));
      this.followersTotal -= 1;
      this.followers.delete(id);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  updateShareType = _async(function* (this: FollowerStore, id: number, shareType: SharingType) {
    try {
      const { data } = yield* _await(api.followers.updateFollower(id, { shareType }));
      const followerData = fromSnapshot<Follower>(data as Follower);
      this.followers.set(id, followerData);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  updateEmailNotifSetting = _async(function* (
    this: FollowerStore,
    id: number,
    getEmailNotif: boolean
  ) {
    try {
      const { data } = yield* _await(api.followers.updateFollowing(id, { getEmailNotif }));
      const followingData = fromSnapshot<Following>(data as Following);
      this.following.set(id, followingData);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  updateSMSNotifSetting = _async(function* (this: FollowerStore, id: number, getSmsNotif: boolean) {
    try {
      const { data } = yield* _await(api.followers.updateFollowing(id, { getSmsNotif }));
      const followingData = fromSnapshot<Following>(data as Following);
      this.following.set(id, followingData);
    } catch (e) {
      // DO error handling
    }
  });

  @modelFlow
  resendSMSInvite = _async(function* (this: FollowerStore, id: number) {
    yield* _await(api.followers.resendSMSInvite(id));
  });

  @modelAction
  reset() {
    this.followers = new Map<number, Follower>();
    this.following = new Map<number, Following>();
    this.followersTotal = 0;
    this.followingTotal = 0;
  }
}
