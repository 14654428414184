import { Button as AntdButton } from "antd";
import { ButtonProps } from "antd/lib/button";
import React from "react";
import { Link } from "react-router-dom";

/**
 * Exactly like antd Button with the exception of returning Link if href is given.
 */
const Button: React.FC<ButtonProps> = ({
  className,
  children,
  href,
  type = "default",
  ...otherProps
}) => {
  if (href) {
    let linkClassName = "ant-btn";
    if (type !== "default") {
      linkClassName += ` ant-btn-${type}`;
    }
    if (className) {
      linkClassName += ` ${className}`;
    }

    return (
      <Link className={linkClassName} to={href}>
        {children}
      </Link>
    );
  }

  return (
    <AntdButton className={className} href={href} type={type} {...otherProps}>
      {children}
    </AntdButton>
  );
};

export default Button;
