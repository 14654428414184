import { Drawer, Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "stores";
import styled from "styled-components";
import urls from "urls";
import Avatar from "./Avatar";

const FancyDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
`;

const FancyMenu = styled(Menu)`
  border-right: 0;

  > .ant-item-divider {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

interface FancyMenuItemProps extends MenuItemProps {
  isPrimary?: boolean;
}

const FancyMenuItem = styled(Menu.Item)<FancyMenuItemProps>`
  color: ${({ isPrimary }) => (isPrimary ? "#205a9d" : "#25282b")};
  font-size: 14px;
  font-weight: ${({ isPrimary }) => (isPrimary ? "600" : "normal")};

  &.ant-menu-item-selected {
    background-color: rgba(32, 90, 157, 0.1) !important;
    font-weight: bold;
  }

  &.ant-menu-item-selected::after {
    background-color: rgb(32, 90, 157);
    left: 0;
    right: inherit;
  }
`;

const ProfileItem = styled(Link)`
  align-items: center;
  display: flex;
  padding: 20px 24px 15px 24px;
`;

const ProfileItemText = styled.span`
  color: #333344;
  flex: 1;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  activeKey?: string;
  onClose?: () => void;
  visible: boolean;
};

const AppDrawer: React.FC<Props> = ({ activeKey, onClose, visible }) => {
  const history = useHistory();
  const store = useStore();
  const { patient, user } = store.auth;
  const { t } = useTranslation();

  const selectedKeys = activeKey ? [activeKey] : undefined;

  const handleOnClick = useCallback(
    ({ key }) => {
      if (key.charAt(0) !== "@") {
        history.push(`${key}`);
      }
    },
    [history]
  );

  if (!user) {
    return null;
  }

  return (
    <FancyDrawer closable={false} onClose={onClose} placement="left" visible={visible}>
      <ProfileItem title={patient?.name || "You"} to={urls.profileDetailPath(user.id)}>
        <Avatar name={patient?.name} src={patient?.picture} />{" "}
        <ProfileItemText>{patient?.name || "You"}</ProfileItemText>
      </ProfileItem>
      <FancyMenu selectedKeys={selectedKeys} mode="inline" onClick={handleOnClick}>
        <FancyMenuItem key={urls.rootPath}>{t("appDrawer.dashboard")}</FancyMenuItem>
        <Menu.Divider />
        <FancyMenuItem key={urls.feedbackPath}>{t("appDrawer.sendFeedback")}</FancyMenuItem>
        <FancyMenuItem key={urls.aboutPath}>{t("appDrawer.aboutUs")}</FancyMenuItem>
        <FancyMenuItem key={urls.settingsPath}>{t("appDrawer.settings")}</FancyMenuItem>
        <FancyMenuItem key={urls.logoutPath}>{t("appDrawer.signOut")}</FancyMenuItem>
      </FancyMenu>
    </FancyDrawer>
  );
};

export default observer(AppDrawer);
