import { Moment } from "moment";
import base, { APIList } from "./base";

export interface APIDashboardItem {
  date: string;
  value?: number;
  severity: number;
  timestamp?: number;
  minute?: number;
}

export type APIGraphType =
  | "temperature"
  | "breathing"
  | "cough"
  | "socialInteraction"
  | "otherSymptoms"
  | "social_interaction"
  | "other_symptoms";

export interface APIDashboardSummary extends Partial<{ [k in APIGraphType]: APIDashboardItem[] }> {
  date: string;
  user: {
    name: string;
    mobileNumber: string;
  };
  summary: APIDashboardItem[];
}

export const summary = (userId?: identifier) =>
  base.get<APIDashboardSummary>(userId ? `dashboard/${userId}/` : "dashboard/");

export interface APIDashboardDetail {
  data: APIDashboardItem[];
  min?: number;
  minSeverity: number;
  max?: number;
  maxSeverity: number;
  avg?: number;
  avgSeverity: number;
}

export const detail = (
  page: string,
  startDate: Moment,
  endDate: Moment,
  group: string,
  userId?: identifier
) =>
  base.get<APIDashboardDetail>(userId ? `dashboard/${userId}/${page}/` : `dashboard/${page}/`, {
    searchParams: {
      start: startDate.clone().locale("en").format("YYYY-MM-DD"),
      end: endDate.clone().locale("en").format("YYYY-MM-DD"),
      group,
    },
  });

export interface APIDashboardNotification {
  id: identifier;
  answer: number;
  finishedAt: string;
  jsonId: string;
  name: string;
  severity: 0 | 1 | 2 | 3;
  userId: identifier;
}

export interface APIDashboardNotificationList<T> extends APIList<T> {
  temperatureId: string;
  breathingId: string;
  coughId: string;
  otherSymptomsId: string;
}

export const notifications = () =>
  base.list<APIDashboardNotificationList<APIDashboardNotification>>("dashboard/notifications/");

export const dismissNotification = (answerId: identifier) =>
  base.deleteEmpty(`dashboard/notifications/${answerId}/~delete/`);
