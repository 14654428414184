import api from "api";
import type { APIPatientIn } from "api/patients";
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { Patient } from "./models";
import type RootStore from "./RootStore";

@model("covid/PatientStore")
export default class PatientStore extends Model({
  patients: prop_mapObject(() => new Map<number, Patient>()),
}) {
  @modelAction
  setPatient(userId: identifier, patient: Patient | null) {
    if (patient) {
      this.patients.set(userId, patient);
    } else {
      this.patients.delete(userId);
    }
  }

  @modelFlow
  fetchProfile = _async(function* (this: PatientStore) {
    const { data } = yield* _await(api.patients.profile());

    const rootStore = getRoot<RootStore>(this);
    const user = rootStore.auth.user!;

    this.patients.set(user.id, new Patient(data as any));
  });

  @modelFlow
  save = _async(function* (this: PatientStore, input: APIPatientIn) {
    const { data } = yield* _await(api.patients.save(input));

    const rootStore = getRoot<RootStore>(this);
    const { auth, settings } = rootStore;
    const user = auth.user!;

    if (input.timezone) {
      settings.setTimezone(input.timezone);
    }

    this.patients.set(user.id, new Patient(data as any));
  });
}
