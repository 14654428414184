import api from "api";
import { APIAuthReauth, APIAuthVerify, APIGeo } from "api/auth";
import config from "config";
import { computed } from "mobx";
import {
  getRoot,
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  stringAsDate,
  _async,
  _await,
} from "mobx-keystone";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Patient, User } from "./models";
import type RootStore from "./RootStore";

@model("covid/AuthStore")
export default class AuthStore extends Model({
  expiry: prop<string | undefined | null>(),
  token: prop<string | undefined | null>(),
  user: prop<User | undefined>(),
  countryCode: prop<string | undefined | null>(),
}) {
  @stringAsDate("expiry")
  expiryDate!: Date;

  @computed
  get isLoggedIn() {
    return !!this.token;
  }

  @computed
  get patient() {
    const rootStore = getRoot<RootStore>(this);

    if (!this.user) {
      return null;
    }

    return rootStore.patients.patients.get(this.user.id);
  }

  @modelFlow
  preLogin = _async(function* (this: AuthStore, mobileNumber: string) {
    yield* _await(api.auth.login(mobileNumber));
  });

  @modelFlow
  preRegister = _async(function* (
    this: AuthStore,
    data: {
      allowResearchersAccredited: boolean;
      allowResearchersCommercial: boolean;
      allowResearchersFreelance: boolean;
      country: string;
      mobileNumber: string;
      language: string;
    }
  ) {
    yield* _await(api.auth.register(data));
  });

  @modelFlow
  logout = _async(function* (this: AuthStore) {
    if (!this.user) {
      return;
    }

    const userId = this.user.id;

    yield* _await(api.auth.logout());

    getRoot<RootStore>(this).reset();

    if (config.gaTrackingCode) {
      ReactGA.ga("send", "pageview", { sessionControl: "end" });
    }
    if (config.gtmCode) {
      const tagManagerArgs = {
        dataLayer: {
          userId: `${userId}`,
          event: "logout",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  });

  @modelFlow
  logoutAll = _async(function* (this: AuthStore) {
    if (!this.user) {
      return;
    }

    const userId = this.user.id;

    yield* _await(api.auth.logoutAll());

    getRoot<RootStore>(this).reset();

    if (config.gaTrackingCode) {
      ReactGA.ga("send", "pageview", { sessionControl: "end" });
    }
    if (config.gtmCode) {
      const tagManagerArgs = {
        dataLayer: {
          userId: `${userId}`,
          event: "logout",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  });

  @modelFlow
  reauth = _async(function* (this: AuthStore) {
    const response = yield* _await(api.auth.reauth());
    this.login(response);
    return response;
  });

  @modelFlow
  verify = _async(function* (this: AuthStore, mobileNumber: string, token: string) {
    const response = yield* _await(api.auth.verify(mobileNumber, token));
    this.login(response);
    return response;
  });

  @modelAction
  login(response: APIAuthReauth | APIAuthVerify) {
    const { expiry, onboarding, patient, settings, token, user } = response.data;

    const rootStore = getRoot<RootStore>(this);

    this.expiry = expiry;
    this.token = token;
    this.user = new User({ ...user });

    api.config.token = response.data.token;

    rootStore.patients.setPatient(
      this.user.id,
      response.data.patient ? new Patient(patient as any) : null
    );
    rootStore.settings.set(settings);
    rootStore.onboarding.setData(onboarding, user);

    // Do not wait for these to complete.
    rootStore.followers.getFollowers();
    rootStore.followers.getFollowing();

    if (config.gaTrackingCode) {
      ReactGA.ga("send", "pageview", { sessionControl: "start" });
      ReactGA.set({ userId: `${user.id}` });
    }
    if (config.gtmCode) {
      const tagManagerArgs = {
        dataLayer: {
          userId: `${user.id}`,
          event: "login",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }

  @modelFlow
  geo = _async(function* (this: AuthStore) {
    let response: APIGeo;
    try {
      response = yield* _await(api.auth.geo());
    } catch (e) {
      this.countryCode = config.defaultCountry;
      return;
    }
    if (response) {
      this.countryCode = response?.data.countryCode;
    }
  });
}
