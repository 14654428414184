import { Model, model, prop } from "mobx-keystone";

@model("covid/User")
export default class User extends Model({
  id: prop<identifier>(),
  allowResearchersAccredited: prop(false),
  allowResearchersCommercial: prop(false),
  allowResearchersFreelance: prop(false),
  country: prop<string>(),
  email: prop<string | null>(),
  emailUnverified: prop<string | null>(),
  mobileNumber: prop<string>(),
}) {}
