import { APIOnboarding } from "api/auth";
import { APIUser } from "api/users";
import { computed } from "mobx";
import { model, Model, modelAction, prop } from "mobx-keystone";

@model("covid/OnboardingStore")
export default class OnboardingStore extends Model({
  patientProfile: prop(false),
  emailVerification: prop(false),
}) {
  @computed
  get isComplete() {
    return this.patientProfile && this.emailVerification;
  }

  @modelAction
  setData(data: APIOnboarding, user: APIUser) {
    this.patientProfile = data.patientProfile;
    this.emailVerification = !!(user.emailUnverified || user.email);
  }

  @modelAction
  setPatientProfile(value: boolean) {
    this.patientProfile = value;
  }

  @modelAction
  setEmailVerification(value: boolean) {
    this.emailVerification = value;
  }
}
