import api from "api";
import {
  fromSnapshot,
  getRoot,
  model,
  Model,
  modelFlow,
  prop,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import { Moment } from "moment";
import { DashboardNotification, DashboardSummary } from "./models/Dashboard";
import type RootStore from "./RootStore";

@model("covid/DashboardStore")
export default class DashboardStore extends Model({
  summaries: prop_mapObject(() => new Map<identifier, DashboardSummary>()),
  notifications: prop_mapObject(() => new Map<identifier, DashboardNotification>()),
  temperatureId: prop<string | null>(null),
  breathingId: prop<string | null>(null),
  coughId: prop<string | null>(null),
  otherSymptomsId: prop<string | null>(null),
}) {
  @modelFlow
  loadSummary = _async(function* (this: DashboardStore, userId?: identifier) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.user) {
      return;
    }

    const data = yield* _await(api.dashboard.summary(userId));
    this.summaries.set(
      userId || rootStore.auth.user.id,
      new DashboardSummary(data as DashboardSummary)
    );
  });

  @modelFlow
  loadDetail = _async(function* (
    this: DashboardStore,
    page: string,
    startDate: Moment,
    endDate: Moment,
    group: string,
    userId?: identifier
  ) {
    const data = yield* _await(api.dashboard.detail(page, startDate, endDate, group, userId));

    // @ts-ignore
    return fromSnapshot<DashboardDetail>(data);
  });

  @modelFlow
  loadNotifications = _async(function* (this: DashboardStore) {
    const { data, temperatureId, breathingId, coughId, otherSymptomsId } = yield* _await(
      api.dashboard.notifications()
    );

    this.notifications.clear();
    data.forEach((notification) => {
      this.notifications.set(notification.id, new DashboardNotification(notification));
    });

    this.temperatureId = temperatureId;
    this.breathingId = breathingId;
    this.coughId = coughId;
    this.otherSymptomsId = otherSymptomsId;
  });

  @modelFlow
  dismissNotification = _async(function* (this: DashboardStore, answerId: identifier) {
    const prevNotification = this.notifications.get(answerId);
    if (!prevNotification) {
      return;
    }

    this.notifications.delete(answerId);

    try {
      yield* _await(api.dashboard.dismissNotification(answerId));
    } catch (e) {
      yield* _await(api.handleError(e, { validationDetails: true }));
      this.notifications.set(answerId, prevNotification);
    }
  });
}
