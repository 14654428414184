import base, { APIDetail, APIList, apiConfig } from "./base";
import type { APISurvey, APISurveyQuestionAnswer } from "./surveys";

export interface APISurveyLog {
  id: identifier;
  date: string;
  finishedAt: timestamp | null;
  startedAt: timestamp | null;
  surveyId: identifier;
  shareToken: string | null;
  shareUrl: string | null;
  answers: APISurveyQuestionAnswer[];
}

export interface APISurveyLogsList extends APIList<APISurveyLog> {
  includes: {
    nextDate: string | null;
  };
}
export interface APISurveyLogsListSearchParams {
  isFinished?: boolean | null | undefined;
}
export const list = (
  surveyId: identifier | string,
  page: number,
  limit: number,
  searchParams: APISurveyLogsListSearchParams = {}
) => {
  const searchParamsActual: any = { ...searchParams };
  if (searchParams.isFinished != null) {
    searchParamsActual.isFinished = `${searchParams.isFinished}`;
  }

  return base.list<APISurveyLogsList>(`surveys/${surveyId}/logs/`, page, limit, {
    searchParams: searchParamsActual,
  });
};

export interface APISurveyLogsDetail extends APIDetail<APISurveyLog> {
  includes: {
    hasNext: boolean;
    hasPrev: boolean;
    nextLogs: APISurveyLog[];
    prevLogs: APISurveyLog[];
  };
}
export interface APISurveyLogsDetailSearchParams extends APISurveyLogsListSearchParams {
  limit?: number;
  paginate?: boolean;
}
export const detail = (
  id: identifier | string,
  searchParams: APISurveyLogsDetailSearchParams = {}
) => {
  const searchParamsActual: any = { limit: apiConfig.defaultLimit, ...searchParams };
  if (searchParams.isFinished != null) {
    searchParamsActual.isFinished = `${searchParams.isFinished}`;
  }
  if (searchParams.paginate != null) {
    searchParamsActual.paginate = `${searchParams.paginate}`;
  }

  return base.get<APISurveyLogsDetail>(`survey_logs/${id}/`, { searchParams: searchParamsActual });
};

export interface APISurveyLogsDetailByShareToken extends APIDetail<APISurveyLog> {
  includes: {
    nextDate: string | null;
    survey: APISurvey;
  };
}
export const detailByShareToken = (shareToken: string) =>
  base.get<APISurveyLogsDetailByShareToken>(`sl/${shareToken}/`, { public: true });

export interface APISurveyLogsShare extends APIDetail<APISurveyLog> {}
export const share = (id: identifier | string) =>
  base.post<APISurveyLogsShare>(`survey_logs/${id}/share/`);

export interface APISurveyLogsUnshare extends APIDetail<APISurveyLog> {}
export const unshare = (id: identifier | string) =>
  base.post<APISurveyLogsUnshare>(`survey_logs/${id}/unshare/`);
