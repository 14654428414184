import localforage from "localforage";

const storage = localforage.createInstance({ name: "covid-sentinel" });

export const storageKeySnapshot = "snapshot";
export const storageKeySurveyDate = (surveyId: identifier | string) => `surveyDate.${surveyId}`;
export const storageKeySurveyPage = (surveyId: identifier | string) => `surveyPage.${surveyId}`;
export const storageKeySurveyQuestionId = (surveyId: identifier | string) =>
  `surveyQuestionId.${surveyId}`;

export default storage;
