import api from "api";
import i18n from "i18next";
import { Model, model, modelAction, modelFlow, prop, _async, _await } from "mobx-keystone";
import moment from "moment";
import { storage, storageKeySnapshot } from "services";
import { changeLanguage } from "utils/i18n";
import AuthStore from "./AuthStore";
import DashboardStore from "./DashboardStore";
import EventStore from "./EventStore";
import FollowerStore from "./FollowerStore";
import OnboardingStore from "./OnboardingStore";
import PatientStore from "./PatientStore";
import SettingsStore from "./SettingsStore";
import StudyStore from "./StudyStore";
import SurveyStore from "./SurveyStore";
import UserStore from "./UserStore";

@model("covid/RootStore")
export default class RootStore extends Model({
  auth: prop<AuthStore>(),
  dashboard: prop<DashboardStore>(),
  events: prop<EventStore>(),
  followers: prop<FollowerStore>(),
  onboarding: prop<OnboardingStore>(),
  patients: prop<PatientStore>(),
  settings: prop<SettingsStore>(),
  studies: prop<StudyStore>(),
  surveys: prop<SurveyStore>(),
  users: prop<UserStore>(),
}) {
  @modelFlow
  load = _async(function* (this: RootStore) {
    // @ts-ignore
    const snapshot: RootStore = yield* _await(storage.getItem(storageKeySnapshot));

    if (snapshot && snapshot.auth && snapshot.auth.token) {
      const { expiry, token } = snapshot.auth;

      api.config.token = token;

      this.auth = new AuthStore({ expiry, token });
    } else {
      changeLanguage(this.settings.language);
    }

    try {
      yield* _await(this.auth.geo());
    } catch (e) {
      // no-op
    }

    if (api.config.token) {
      try {
        yield* _await(this.auth.reauth());
      } catch (e) {
        const response = yield* _await(api.handleError(e));

        if (response.code !== "generic/network") {
          this.reset();
        } else {
          changeLanguage(this.settings.language);
        }
      }
    }
  });

  @modelAction
  reset() {
    i18n.changeLanguage("en");
    moment.locale("en");
    moment.tz.setDefault();

    this.auth = new AuthStore({});
    this.dashboard = new DashboardStore({});
    this.events = new EventStore({});
    this.followers = new FollowerStore({
      followersTotal: 0,
      followingTotal: 0,
      maxFollowers: 10,
    });
    this.onboarding = new OnboardingStore({});
    this.patients = new PatientStore({});
    this.settings = new SettingsStore({});
    this.studies = new StudyStore({});
    this.surveys = new SurveyStore({});
    this.users = new UserStore({});

    this.auth.geo();

    api.config.token = "";
  }
}
