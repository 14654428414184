import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Calendar, { CalendarProps, CalendarType } from "react-calendar";
import { useTranslation } from "react-i18next";
import type { SettingsWeekStart } from "stores/SettingsStore";
import styled from "styled-components";
import { fixMomentToDateConversion } from "utils/dates";

const StyledCalendarBase = styled(Calendar)`
  border: none;
  font-family: inherit;
  font-size: 14px;

  .react-calendar__navigation__label {
    font-size: 16px;
  }

  .react-calendar__tile:disabled,
  .react-calendar__navigation button[disabled] {
    background: white;
    color: #e7e8e9;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #333344;
  }

  .react-calendar__tile {
    margin: 2px 0;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding: 0 4px;

    abbr,
    span {
      flex: 1;
      padding: 8px 4px;
    }
  }

  .react-calendar__tile--now {
    background: transparent;

    abbr {
      background: rgba(32, 90, 157, 0.04);
      color: rgb(32, 90, 157);
    }
  }

  .react-calendar__tile:enabled:hover {
    background: transparent;

    abbr {
      background: rgba(32, 90, 157, 0.04);
      color: rgb(32, 90, 157);
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive,
  .react-calendar__tile--active:hover,
  .react-calendar__tile--active:focus,
  .react-calendar__tile:enabled:focus {
    background: transparent;

    abbr {
      background: rgba(32, 90, 157, 0.1) !important;
      color: #205a9d !important;
      border-radius: 2px;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #bdbdbd;
  }
`;

interface StyledCalendarProps extends CalendarProps {
  weekStart?: SettingsWeekStart;
}

const StyledCalendar: React.FC<StyledCalendarProps> = ({ weekStart, ...otherProps }) => {
  const {
    i18n: { language },
  } = useTranslation();

  let calendarType: CalendarType = "ISO 8601";
  if (weekStart === "6") {
    calendarType = "Arabic";
  } else if (weekStart === "7") {
    calendarType = "US";
  }

  let locale = "en-US";
  switch (language) {
    case "fr":
      locale = "fr-FR";
      break;
    case "es":
      locale = "es-ES";
      break;
    case "tl":
      locale = "fil-PH";
      break;
    case "ar":
      locale = "ar-SA";
      break;
    case "de":
      locale = "de-DE";
      break;
    case "hi":
      locale = "hi-IN";
      break;
    case "it":
      locale = "it-IT";
      break;
    case "ms":
      locale = "ms-MY";
      break;
    case "pt":
      locale = "pt-PT";
      break;
    case "pt-br":
      locale = "pt-BR";
      break;
    case "ru":
      locale = "ru-RU";
      break;
    case "sw":
      locale = "sw-KE";
      break;
    case "zh-hans":
      locale = "zh-HANS";
      break;
    case "cs":
      locale = "cs-CZ";
      break;
    case "ja":
      locale = "ja-JP";
      break;
    case "ind":
      locale = "id-ID";
      break;
    case "bn":
      locale = "bn-BN";
      break;
    case "uk":
      locale = "uk";
      break;
    case "ur":
      locale = "ur-PK";
      break;
    default:
      locale = "en-US";
      break;
  }

  return (
    <StyledCalendarBase
      calendarType={calendarType}
      locale={locale}
      maxDate={fixMomentToDateConversion(moment())}
      nextLabel={<FontAwesomeIcon icon={["fas", "chevron-right"]} />}
      next2Label={<FontAwesomeIcon icon={["fas", "chevron-double-right"]} />}
      prevLabel={<FontAwesomeIcon icon={["fas", "chevron-left"]} />}
      prev2Label={<FontAwesomeIcon icon={["fas", "chevron-double-left"]} />}
      {...otherProps}
    />
  );
};

export default StyledCalendar;
