import { Moment } from "moment";
import type { ComparisonTypes, SurveyCadence, SurveyJsonId, SurveyQuestionType } from "stores/models/Survey";
import base, { APIDetail, APIList } from "./base";
import type { APISurveyLog } from "./surveyLogs";

export interface APISurveyQuestionAnswer {
  answer: string;
  choices: identifier[];
  isSkipped: boolean;
  questionId: identifier;
}

export interface APISurveyQuestionChoice {
  id: identifier;
  text: string;
}

export interface APISurveyQuestionShowOn {
  choices: identifier[];
  targetQuestion: identifier;
  isAnswered: boolean;
  useChoices: boolean;
  comparisonType: ComparisonTypes,
  comparisonReference: identifier;
}

export interface APISurveyQuestion {
  id: identifier;
  choices: APISurveyQuestionChoice[];
  maxRange: decimalstring | null;
  minRange: decimalstring | null;
  showOn: APISurveyQuestionShowOn[];
  step: decimalstring | null;
  text: string;
  type: SurveyQuestionType;
}

export interface APISurveySection {
  id: identifier;
  title: string;
  questions: APISurveyQuestion[];
}

export interface APISurvey {
  id: identifier;
  cadence: SurveyCadence;
  jsonId: SurveyJsonId;
  sections: APISurveySection[];
  title: string;
  study: identifier;
  consentFor: identifier;
}

export interface APISurveysDetail extends APIDetail<APISurvey> {}
export const detail = (id: identifier | string, isHistory = false) =>
  base.get<APISurveysDetail>(`surveys/${id}/`, { searchParams: { isHistory } });

export interface APISurveysList extends APIList<APISurvey> {}
export const list = (page = 1, searchParams = {}) =>
  base.list<APISurveysList>("surveys/", page, 0, { searchParams });

export type APISurveysGetById = APISurveysDetail;
export const getById = (id: SurveyJsonId) =>
  base.get<APISurveysGetById>("surveys/get_by_id/", { searchParams: { id } });

export interface APISurveysGetSurveyAnswers extends APIDetail<APISurveyLog> {}
export const getSurveyAnswers = (surveyId: identifier | string, date: Moment) =>
  base.get<APISurveysGetSurveyAnswers>("surveys/answers_by_id/", {
    searchParams: { id: surveyId, date: date.clone().locale("en").format("YYYY-MM-DD") },
  });

export interface APISurveysAnswerInput {
  answers: APISurveyQuestionAnswer[];
  date?: string;
  logId?: identifier;
}
// export interface APISurveysAnswerOutput extends APIDetail<Omit<APISurveyLog, "answers">> {}
export interface APISurveysAnswerOutput extends APIDetail<APISurveyLog> {}
export const answer = (id: identifier | string, data: APISurveysAnswerInput) =>
  base.post<APISurveysAnswerOutput>(`surveys/${id}/answer/`, { json: data });

export interface APISurveyStatus {
  id: identifier;
  hasFinishedOnce: boolean;
  since: timestamp;
  survey: {
    id: identifier;
    cadence: string;
    isHidden: boolean;
    jsonId: string;
    order: number;
    partOfStudy: boolean;
    supportsNotifications: boolean;
    title: string;
  };
  logCurrent: {
    id: identifier;
    finishedAt: timestamp | null;
    startedAt: timestamp;
  } | null;
  logLastFinished: {
    id: identifier;
    finishedAt: timestamp;
    startedAt: timestamp;
  } | null;
}

export type APISurveyStatusList = Partial<
  {
    [jsonId in SurveyJsonId]: APISurveyStatus;
  }
>;

export interface APISurveysDailyStatus {
  data: {
    dailyIntroQuestionId: identifier | null;
    dailyJsonId: SurveyJsonId;
    statuses: APISurveyStatusList;
  };
}
export const statuses = async () => base.get<APISurveysDailyStatus>("surveys/statuses/");
