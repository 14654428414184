import type {
  PatientBloodTypes,
  PatientEthnicities,
  PatientGenders,
  PatientHeightUnits,
  PatientSexes,
  PatientWeightUnits,
} from "stores/models/Patient";
import base, { APIDetail } from "./base";

export interface APIPatientIn {
  age: number;
  name: string;
  mobileNumber?: string;
  sex: PatientSexes;
  bloodType?: PatientBloodTypes;
  country?: string;
  ethnicity?: PatientEthnicities;
  ethnicityOther: string;
  height?: number | null;
  heightUnits?: PatientHeightUnits;
  picture?: string | null;
  timezone?: string;
  weight?: number | null;
  weightUnits?: PatientWeightUnits;
  zip?: string;
  gender?: PatientGenders,
  genderOther: string;
}

export interface APIPatientOut extends Required<Omit<APIPatientIn, "timezone">> {}

export interface APIPatientsPictureIn {
  picture?: any;
  pictureTemp?: any;
}

export interface APIPatientsPictureOut {
  picture: string | null;
  pictureTemp: string | null;
}

export interface APIPatientsProfile extends APIDetail<APIPatientOut> {}
export const profile = () => base.get<APIPatientsProfile>("profiles/patient/");

export interface APIPatientsSave extends APIDetail<APIPatientOut> {}
export const save = (data: APIPatientIn) =>
  base.post<APIPatientsSave>("profiles/patient/", { json: data });

export interface APIPatientsPictureUpload extends APIDetail<APIPatientsPictureOut> {}
export const pictureUpload = (picture: Blob, filename: string) => {
  const formData = new FormData();
  formData.append("picture", picture, filename);

  return base.post<APIPatientsPictureUpload>("profiles/picture/", { body: formData });
};
