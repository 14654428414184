import { Spin } from "antd";
import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as Icon } from "./icon.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(Icon)`
  animation: ${rotate} 0.8s ease-out infinite;
`;

const FullScreenSpin = styled(Spin)`
  && {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`;

const TopScreenSpin = styled(Spin)`
  && {
    align-items: flex-start;
    display: flex;
    padding-top: 20px;
    justify-content: center;
  }
`;

export interface Props {
  fullscreen?: boolean;
  spinning?: boolean;
  topScreen?: boolean;
}

const Loadable: React.FC<Props> = ({ children, fullscreen, spinning = true, topScreen }) => {
  if (fullscreen) {
    return (
      <FullScreenSpin indicator={<StyledIcon />} spinning={spinning}>
        {children}
      </FullScreenSpin>
    );
  }

  if (topScreen) {
    return (
      <TopScreenSpin indicator={<StyledIcon />} spinning={spinning}>
        {children}
      </TopScreenSpin>
    );
  }

  return (
    <Spin indicator={<StyledIcon />} spinning={spinning}>
      {children}
    </Spin>
  );
};

export default Loadable;
