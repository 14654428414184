import { EventEmitter } from "events";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export const events = new EventEmitter();

export interface FlashMessage {
  closable?: boolean;
  duration?: number;
  float?: boolean;
  id: string;
  message: string | React.ReactNode;
  type: string;
}

export interface FlashMessageList {
  [id: string]: FlashMessage;
}

export type FlashType = "error" | "info" | "success" | "warning";

export function clear(id?: string) {
  events.emit("clear", { id });
}

export function flash(message: PartialBy<FlashMessage, "id">) {
  events.emit("flash", { id: uuidv4(), closable: true, duration: 5000, float: true, ...message });
}

const FlashContext = React.createContext<
  [
    FlashMessageList,
    (
      flashMessageList:
        | FlashMessageList
        | ((flashMessageList: FlashMessageList) => FlashMessageList)
    ) => void
  ]
>([{}, (flashMessageList) => {}]);
export const useFlash = () => React.useContext(FlashContext);
export const { Provider: FlashProvider } = FlashContext;
