import { computed } from "mobx";
import { model, Model, prop } from "mobx-keystone";
import moment from "moment";

@model("covid/Event")
class Event extends Model({
  id: prop<identifier>(),
  user: prop<identifier>(),
  created: prop<string>(),
  type: prop<"survey_completed" | "shared_profile" | "started_following">(),
  data: prop<
    | {
        logId: identifier;
        surveyId: string;
      }
    | { userId: identifier }
  >(),
}) {
  @computed
  get createdDate() {
    return moment(this.created);
  }
}

export { Event };
