import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { events } from "services";
import { FlashMessage, FlashMessageList, FlashType, useFlash } from "services/events";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer/polyfilled";
import { desktopMinWidth } from "utils/constants";
import Alert from "./Alert";
import Header from "./Header";

const Container = styled.div`
  flex: 1;
  hyphens: auto;
  overflow: auto;
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
`;

const ContainerContent = styled.div`
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${desktopMinWidth};
`;

interface AlertListProps {
  top: number;
}

const AlertList = styled.div<AlertListProps>`
  background: white;
  position: fixed;
  top: ${({ top }) => `${top}px`};
  width: 100%;
  z-index: 3;

  > * {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  z-index: 200;
`;

interface Props {
  activeMenuKey?: string;
  backUrl?: string;
  className?: string;
  containerClassName?: string;
  hasLeftMenu?: boolean;
  header?: React.ReactNode;
  persistFlash?: boolean;
  rightView?: React.ReactNode;
  showBackButton?: boolean;
  title?: string;
}

const BaseContainer: React.FC<Props> = ({
  activeMenuKey,
  backUrl,
  children,
  className,
  containerClassName,
  hasLeftMenu,
  header,
  persistFlash,
  rightView,
  showBackButton,
  title,
}) => {
  const [flashMessagesBlock, setFlashMessagesBlock] = useState<FlashMessageList>({});
  const [flashMessagesFloating, setFlashMessagesFloating] = useFlash();
  const { height: headerHeight = 0, ref: headerRef } = useResizeObserver<HTMLDivElement>();
  const { i18n } = useTranslation();

  useEffect(() => {
    events.addListener("clear", ({ id }) => {
      setFlashMessagesBlock((prevFlashMessages) => {
        if (id) {
          const newFlashMessages = { ...prevFlashMessages };
          delete newFlashMessages[id];
          return newFlashMessages;
        }

        return {};
      });

      setFlashMessagesFloating((prevFlashMessages) => {
        if (id) {
          const newFlashMessages: FlashMessageList = { ...prevFlashMessages };
          delete newFlashMessages[id];
          return newFlashMessages;
        }

        return {};
      });
    });

    return () => {
      if (!persistFlash) setFlashMessagesFloating({});
      events.removeListener("clear", () => {});
    };
  }, [persistFlash, setFlashMessagesFloating]);

  useEffect(() => {
    events.addListener("flash", (flashMessage: FlashMessage) => {
      const setter = flashMessage.float ? setFlashMessagesFloating : setFlashMessagesBlock;

      if (!flashMessage.message) {
        console.log(`Has empty flash message for ${flashMessage.id}:`, flashMessage);
        Sentry.withScope((scope) => {
          scope.setLevel(Severity.Info);
          scope.setExtra("flashMessage", flashMessage);
          Sentry.captureMessage(`Has empty flash message for ${flashMessage.id}.`);
        });
        return;
      }

      setter((prevFlashMessages) => ({
        ...prevFlashMessages,
        [flashMessage.id]: flashMessage,
      }));

      if (flashMessage.duration) {
        setTimeout(() => {
          setter((prevFlashMessages) => {
            const newFlashMessages = { ...prevFlashMessages };
            delete newFlashMessages[flashMessage.id];
            return newFlashMessages;
          });
        }, flashMessage.duration);
      }
    });

    return () => {
      events.removeListener("flash", () => {});
    };
  }, [setFlashMessagesFloating]);

  const handleFlashMessageBlockClose = (id: string) => {
    setFlashMessagesBlock((prevFlashMessages) => {
      const newFlashMessages = { ...prevFlashMessages };
      delete newFlashMessages[id];
      return newFlashMessages;
    });
  };

  const handleFlashMessageFloatingClose = (id: string) => {
    setFlashMessagesFloating((prevFlashMessages) => {
      const newFlashMessages = { ...prevFlashMessages };
      delete newFlashMessages[id];
      return newFlashMessages;
    });
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} title={title} />
      <HeaderContainer ref={headerRef}>
        {header || (
          <Header
            activeMenuKey={activeMenuKey}
            backUrl={backUrl}
            hasLeftMenu={hasLeftMenu}
            rightView={rightView}
            showBackButton={showBackButton}
          />
        )}
      </HeaderContainer>

      {Object.values(flashMessagesBlock).map(({ closable, id, message, type }) => (
        <Alert
          afterClose={() => handleFlashMessageBlockClose(id)}
          banner
          closable={closable}
          key={id}
          message={message}
          showIcon={false}
          type={type as FlashType}
        />
      ))}

      <AlertList top={headerHeight}>
        {Object.values(flashMessagesFloating).map(({ closable, id, message, type }) => (
          <Alert
            afterClose={() => handleFlashMessageFloatingClose(id)}
            banner
            closable={closable}
            float
            key={id}
            message={message}
            showIcon={false}
            type={type as FlashType}
          />
        ))}
      </AlertList>

      <Container className={containerClassName} id="container">
        <ContainerContent className={className} id="container-content">
          {children}
        </ContainerContent>
      </Container>
    </>
  );
};

export default BaseContainer;
