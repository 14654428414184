import { Loadable } from "components";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useStore } from "stores";
import urls from "urls";

type Props = {};

const LogoutPage: React.FC<Props> = () => {
  const { auth } = useStore();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    (async () => {
      if (auth.isLoggedIn) {
        await auth.logout();
      }
      setIsLoggedOut(true);
    })();
  }, [auth, setIsLoggedOut]);

  if (isLoggedOut) {
    return <Redirect to={urls.rootPath} />;
  }

  return <Loadable spinning topScreen />;
};

export default observer(LogoutPage);
