import { TFunction } from "i18next";
import moment from "moment";
import { SettingsWeekStart } from "stores/SettingsStore";
import { getStartOfWeek } from "./dates";

export function fahrenheitToCelsius(fahrenheit: number) {
  return ((5.0 * (fahrenheit - 32)) / 9.0).toFixed(1);
}

export function severityToColor(severity: number) {
  switch (severity) {
    case 1:
      return "#205a9d";
    case 2:
      return "#f89a46";
    case 3:
      return "#eb5757";
    default:
      return "#c4c4c4";
  }
}

export function breathingValueToText(value: string | undefined, t: TFunction) {
  switch (value) {
    case "1":
    case "2":
      return t("dashboardPage.breathing.none");
    case "3":
    case "4":
      return t("dashboardPage.breathing.mild");
    case "5":
    case "6":
    case "7":
      return t("dashboardPage.breathing.moderate");
    case "8":
    case "9":
      return t("dashboardPage.breathing.severe");
    case "10":
      return t("dashboardPage.breathing.extreme");
  }
  return t("dashboardPage.breathing.none");
}

export function coughValueToText(value: string | undefined, t: TFunction) {
  switch (value) {
    case "1":
      return t("dashboardPage.cough.none");
    case "2":
    case "3":
    case "4":
      return t("dashboardPage.cough.mild");
    case "5":
    case "6":
    case "7":
      return t("dashboardPage.cough.moderate");
    case "8":
    case "9":
      return t("dashboardPage.cough.severe");
    case "10":
      return t("dashboardPage.cough.worstEver");
  }
  return t("dashboardPage.cough.none");
}

export function socialInteractionValueToText(value: number | undefined) {
  switch (value?.toFixed(0)) {
    case "0":
      return "0";
    case "1":
      return "1-2";
    case "2":
      return "3-5";
    case "3":
      return "6-10";
    case "4":
      return "11-15";
    case "5":
      return ">15";
  }
  return "";
}

const morning = [6 * 60, 12 * 60 - 1];
const afternoon = [12 * 60, 18 * 60 - 1];
const evening = [18 * 60, 24 * 60 - 1];
const night = [0, 6 * 60 - 1];

export const HOURLY_TICKS = [0, 9 * 60, 15 * 60, 21 * 60, 3 * 60];

export function tickFormatter(
  tick: string | number,
  weekStart: SettingsWeekStart,
  t: TFunction
): [boolean, string] | [boolean, string, string] {
  const today = moment().startOf("day");
  const tickNum = tick as number;
  const tickStr = tick as string;

  if (tickStr.indexOf) {
    if (tickStr.indexOf("/") >= 0) {
      const [, dt] = tickStr.split("/");
      if (dt.indexOf("W") >= 0) {
        const tickStart = getStartOfWeek(
          moment().week(parseInt(dt.substring(1), 10)),
          weekStart
        ).startOf("day");
        const tickEnd = tickStart.clone().add(6, "days").endOf("day");
        const isTickThisWeek = today.isSameOrAfter(tickStart) && today.isSameOrBefore(tickEnd);
        return [isTickThisWeek, dt, `${tickStart.format("D")} - ${tickEnd.format("D")}`];
      }
      const tickMonth = moment()
        .month(parseInt(dt, 10) - 1)
        .startOf("month");
      const isTickThisMonth = today.isSame(tickMonth, "month");
      return [isTickThisMonth, tickMonth.format("MMM")];
    }

    const tickDay = moment(tick).startOf("day");
    const isTickToday = today.isSame(tickDay, "day");
    return [isTickToday, tickDay.format("ddd"), tickDay.format("D")];
  }

  const currMinute = (moment().unix() - moment().startOf("day").unix()) / 60;
  if (tickNum === HOURLY_TICKS[1]) {
    return [
      currMinute >= morning[0] && currMinute <= morning[1],
      t("dashboardPage.hourly.morning"),
    ];
  }
  if (tickNum === HOURLY_TICKS[2]) {
    return [
      currMinute >= afternoon[0] && currMinute <= afternoon[1],
      t("dashboardPage.hourly.afternoon"),
    ];
  }
  if (tickNum === HOURLY_TICKS[3]) {
    return [
      currMinute >= evening[0] && currMinute <= evening[1],
      t("dashboardPage.hourly.evening"),
    ];
  }
  if (tickNum === HOURLY_TICKS[4]) {
    return [currMinute >= night[0] && currMinute <= night[1], t("dashboardPage.hourly.night")];
  }
  return [false, ""];
}
