import { Alert as AntdAlert } from "antd";
import React from "react";
import type { FlashType } from "services/events";
import styled, { css } from "styled-components";

const StyledAlert = styled(AntdAlert)`
  border-width: 0;

  ${({ type }) => {
    if (type === "error") {
      return css`
        background: rgba(235, 87, 87, 0.1);
        border-bottom: 4px solid #eb5757;
      `;
    } else if (type === "info") {
      return css`
        background: rgba(32, 89, 173, 0.1);
        border-bottom: 4px solid #2059ad;
      `;
    }

    // TODO: Implement success and warning as soon as the designs are ready.
    return css`
      background: rgba(32, 89, 173, 0.1);
      border-bottom: 4px solid #2059ad;
    `;
  }}

  a:link, a:visited {
    text-decoration: underline;
  }
`;

interface Props {
  afterClose?: () => void;
  banner?: boolean;
  className?: string;
  closable?: boolean;
  float?: boolean;
  message: string | React.ReactNode;
  onClose?: () => void;
  showIcon?: boolean;
  type: FlashType;
}

const Alert: React.FC<Props> = ({
  afterClose,
  banner,
  className,
  closable,
  message,
  onClose,
  showIcon,
  type,
}) => {
  return (
    <StyledAlert
      afterClose={afterClose}
      banner={banner}
      className={className}
      closable={closable}
      message={message}
      onClose={onClose}
      showIcon={showIcon}
      type={type}
    />
  );
};

export default Alert;
