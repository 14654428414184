import { Moment } from "moment";
import type { SettingsWeekStart } from "stores/SettingsStore";

export function getStartOfWeek(date: Moment, weekStart: SettingsWeekStart) {
  const dateGiven = date.clone();

  if (weekStart === "1") {
    if (dateGiven.day() === 0) {
      return dateGiven.day(-6);
    } else {
      return dateGiven.day(1);
    }
  }

  if (weekStart === "6") {
    if (dateGiven.day() === 6) {
      return dateGiven.day(6);
    } else {
      return dateGiven.day(6).subtract(7, "days");
    }
  }

  return dateGiven.day(0);
}

export function fixMomentToDateConversion(momentDate: Moment) {
  const today = momentDate.clone().toArray().slice(0, 3) as [number, number, number];
  return new Date(...today);
}
