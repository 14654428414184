import Avatar from "components/Avatar";
import React, { useState } from "react";
import { Patient } from "stores/models";
import styled from "styled-components";
import AvatarEditModal from "./AvatarEditModal";
import { ReactComponent as EditIcon } from "./iconEdit.svg";

const Container = styled.div`
  position: relative;
`;

interface EditContainerProps {
  disabled: boolean | undefined;
}

const EditContainer = styled.button<EditContainerProps>`
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #bdbdbd;
  bottom: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  height: 40px;
  overflow: hidden;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  width: 40px;
`;

interface Props {
  disabled?: boolean;
  patient?: Patient | null;
  size: number;
}

const AvatarEdit: React.FC<Props> = ({ disabled, patient, size = 104 }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState(0);

  const handleHideModal = () => {
    setIsEditing(false);
    setKey((prevKey) => prevKey + 1);
  };

  const handleShowModal = () => {
    setIsEditing(true);
  };

  const handleSave = (url: string | null) => {
    if (url) {
      patient?.setPicture(url);
    }

    setIsEditing(false);
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <Container>
        <Avatar name={patient?.name} size={size} src={patient?.picture} />

        <EditContainer disabled={disabled} onClick={handleShowModal}>
          <EditIcon />
        </EditContainer>
      </Container>

      <AvatarEditModal
        key={key}
        onCancel={handleHideModal}
        onSave={handleSave}
        visible={isEditing}
      />
    </>
  );
};

export default AvatarEdit;
